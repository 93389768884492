<template>
  <div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <v-card class="sober d-flex flex-column">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title> जग्गाको विवरण (क्षेत्रफल) </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="mx-4 my-4" v-model="valid">
        <!-- <v-row class="mt-2">
          <v-col cols="2">
            <label> भू-उपयोग क्षेत्र<span> *</span></label>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="newLandinfoarea.land_use_area_id"
              :items="landUseArea"
              item-value="id"
              class="custom-text-field"
              item-text="name"
              label="भू-उपयोग क्षेत्र"
              :rules="[required('Land Use Type')]"
            ></v-select>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="2" md="2">
            <label>म्याप शीट नं<span> *</span></label>
          </v-col>
          <v-col cols="4" md="4">
            <v-text-field
              v-model="newLandinfoarea.map_sheet_no"
              label="म्याप शीट नं"
              :rules="[required('म्याप शीट नं')]"
            ></v-text-field>
          </v-col>
          <!-- <v-col md="2"></v-col> -->
          <v-col cols="2" md="2">
            <label>जग्गा कित्ता नं<span> *</span></label>
          </v-col>
          <v-col cols="4" md="4">
            <v-text-field
              v-model="newLandinfoarea.land_plot_no"
              :rules="[required('Land Plot No.')]"
              label="जग्गा कित्ता नं"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="font-weight-bold table-title text-bottom"> क्षेत्रफल </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <v-checkbox v-model="ropaniDivChecked" label="आनामा" hide-details></v-checkbox>
          </v-col>
          <v-col cols="12" md="12" v-if="ropaniDivChecked">
            <v-row>
              <v-col cols="3" md="1"> रोपनी </v-col>
              <v-col cols="3" md="2">
                <v-text-field v-model="newLandinfoarea.area_ropani" label="रोपनी"></v-text-field>
              </v-col>
              <v-col cols="3" md="1"> आना </v-col>
              <v-col cols="3" md="2">
                <v-text-field v-model="newLandinfoarea.area_aana" label="आना"></v-text-field>
              </v-col>
              <v-col cols="3" md="1"> पैसा </v-col>
              <v-col cols="3" md="2">
                <v-text-field v-model="newLandinfoarea.area_paisa" label="पैसा"></v-text-field>
              </v-col>
              <v-col cols="3" md="1"> दाम </v-col>
              <v-col cols="3" md="2">
                <v-text-field v-model="newLandinfoarea.area_daam" label="दाम"></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2">
            <v-checkbox v-model="bighaDivChecked" label="कठ्ठामा" hide-details></v-checkbox>
          </v-col>
          <v-col cols="12" md="10" class="bigha-div" v-if="bighaDivChecked">
            <v-row>
              <v-col cols="3" md="1"> बिघा </v-col>
              <v-col cols="3" md="2">
                <v-text-field v-model="newLandinfoarea.area_bigha" label="बिघा"></v-text-field>
              </v-col>
              <v-col cols="3" md="1"> कठ्ठा </v-col>
              <v-col cols="3" md="2">
                <v-text-field v-model="newLandinfoarea.area_kattha" label="कठ्ठा"></v-text-field>
              </v-col>
              <v-col cols="3" md="1"> धुर </v-col>
              <v-col cols="3" md="2">
                <v-text-field v-model="newLandinfoarea.area_dhur" label="धुर"></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" md="2">
            <label> Area (sqm) </label>
          </v-col>
          <v-col cols="4" md="4">
            <v-text-field
              @focus="sqmflag = true"
              @blur="sqmflag = false"
              v-model="newLandinfoarea.area_sqm"
              label="Area (sqm)"
            ></v-text-field>
          </v-col>
          <!-- <v-col md="2"></v-col> -->
          <v-col cols="2" md="2">
            <label>Area (sqft) </label>
          </v-col>
          <v-col cols="4" md="4">
            <v-text-field
              @focus="sqftflag = true"
              @blur="sqftflag = false"
              v-model="newLandinfoarea.area_sqft"
              label="Area (sqft)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="2"> कैफियत </v-col>
          <v-col cols="6" md="2">
            <v-text-field v-model="newLandinfoarea.remarks" label=""></v-text-field>
          </v-col>
          <v-col cols="6" md="2"> Standard Setback (m) </v-col>
          <v-col cols="6" md="2">
            <v-text-field v-model="newLandinfoarea.standard_setback" label=""></v-text-field>
          </v-col>
          <v-col cols="6" md="2"> Actual Setback (m) </v-col>
          <v-col cols="6" md="2">
            <v-text-field v-model="newLandinfoarea.actual_setback" label=""></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <v-btn
              primary
              v-if="isPostAuthorized"
              v-on:click="save(false)"
              class="primary"
              :disabled="!valid"
              >Save</v-btn
            >
            <v-btn
              primary
              v-on:click="save(true)"
              v-if="isPostAuthorized"
              class="primary ml-1"
              :disabled="!valid"
              >Save &amp; Next</v-btn
            >
            <v-btn class="ma-1" color="primary" @click="nextStep(1)"> Skip </v-btn>
          </v-col>
        </v-row>
        <br />
        <v-snackbar
          v-model="snackbar.show"
          :bottom="y === 'bottom'"
          :color="snackbar.color"
          :left="x === 'left'"
          :multi-line="mode === 'multi-line'"
          :right="x === 'right'"
          :timeout="3000"
          :top="y === 'top'"
          :vertical="mode === 'vertical'"
        >
          {{ successfulMessage }}
        </v-snackbar>
        <br />
      </v-form>
      <v-toolbar color="primary mt-2" dark flat>
        <v-toolbar-title> List </v-toolbar-title>
      </v-toolbar>
      <v-simple-table class="sober">
        <template v-slot:default>
          <thead>
            <tr class="registrationtable">
              <th class="text-left registrationtable table-title">म्याप शीट नं</th>
              <th class="text-left registrationtable table-title">जग्गा कित्ता नं</th>
              <th class="text-left registrationtable table-title">Actual Setback</th>
              <th class="text-left registrationtable table-title">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="registrationtable"
              v-for="landinfoareaList in landinfoarea"
              :key="landinfoareaList.id"
            >
              <td class="registrationtable">
                {{ landinfoareaList.map_sheet_no }}
              </td>
              <td class="registrationtable">
                {{ landinfoareaList.land_plot_no }}
              </td>
              <td>{{ landinfoareaList.actual_setback }}</td>
              <td>
                <v-btn
                  small
                  color="primary"
                  v-if="isPostAuthorized"
                  v-on:click="edit(landinfoareaList.id)"
                  >Edit</v-btn
                >
                <!-- <v-btn
                  small
                  color="primary"
                  v-if="isPostAuthorized"
                  v-on:click="deleterecord(landinfoareaList.id)"
                  >Delete</v-btn
                > -->
                <custom-dialog
                  v-if="isPostAuthorized"
                  :click-dialog="deleterecord"
                  :regId="landinfoareaList.id"
                  click-type="delete"
                  >Delete</custom-dialog
                >
                <v-btn small color="primary" v-else v-on:click="edit(landinfoareaList.id)"
                  >View</v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <form-history :historyData="historyData"></form-history>
  </div>
</template>
<script>
import { engNumConvert } from "../../mixins/numericToUnicode.js";
import FormHistory from "../../history/FormHistory.vue";

export default {
  name: "Landinfoarea",
  mixins: [engNumConvert],
  props: {
    nextStep: {
      type: Function,
    },
    registrationId: {
      type: [String, Number],
    },
    id: {
      type: [String, Number],
    },
    application: {
      type: Object,
    },
    // isPostAuthorized: {
    //   type: Boolean,
    // },
    regConstructionId: {
      type: Number,
    },
  },
  components: {
    FormHistory,
  },

  data() {
    return {
      valid: false,
      same_as_old_data: false,
      historyData: {
        apiUrl: `api/registration/${this.registrationId}/land-info-area/history`,
        formData: [
          // {
          //   title: "भू-उपयोग क्षेत्र",
          //   key: "land_use_area",
          // },
          {
            title: "म्याप शीट नं",
            key: "map_sheet_no",
          },
          {
            title: "जग्गा कित्ता नं",
            key: "land_plot_no",
          },
        ],
      },
      ropaniDivChecked: true,
      bighaDivChecked: false,
      required(propertyType) {
        return (v) => !!v || `${propertyType} is required`;
      },
      landUseArea: [],
      // { id: 1, value: "आवासिय" },
      // { id: 2, value: "व्यवसायिक" },
      // { id: 3, value: "स्वास्थ्य" },
      // { id: 4, value: "शिक्षा" },
      // { id: 5, value: "सरकारी र अर्धसरकारी" },
      // { id: 6, value: "जमघट" },
      // { id: 7, value: "औद्योगिक" },
      // { id: 8, value: "होटल सेवा र सुविधा वितरण" },
      // { id: 9, value: "अपार्टमेन्ट" },
      // { id: 10, value: "संघसंस्था" },
      // { id: 11, value: "अन्य" },
      landinfoarea: [],
      isValid: true,
      isLoading: true,
      userRoleId: localStorage.getItem("auth_user_role_id"),
      newLandinfoarea: {
        id: 0,
        reg_id: this.registrationId,
        map_sheet_no: "",
        land_plot_no: "",
        area_ropani: "",
        area_aana: "",
        area_paisa: "",
        area_daam: "",
        area_bigha: "",
        area_kattha: "",
        area_dhur: "",
        standard_setback: "",
        actual_setback: "",
        remarks: "",
        land_use_area_id: "",
        area_sqm: "",
        area_sqft: "",
      },
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      sqmflag: false,
      sqftflag: false,
      isPostAuthorized: false,
    };
  },
  created() {
    this.getLandUseArea();
    this.getNibedanAuthorization();
  },
  mounted() {
    const url = `api/registration/${this.registrationId}/land-info-area`;

    this.$axios
      .get(url, {
        timeout: 100000,
      })
      .then((response) => {
        // debugger;
        this.landinfoarea = response.data.data;
        this.edit(this.landinfoarea[0].id);
        this.isLoading = false; //stop the loading spinner
        // this.newLandinfoarea.clear();
      })
      .catch((error) => {
        console.log(`api error:${error}`);
        this.isLoading = false; //stop the loading spinner
      });

    this.getRegLandUseArea();
  },
  computed: {
    areaSqFt: function () {
      let areaFt = 0;
      if (
        !!this.newLandinfoarea.area_ropani ||
        !!this.newLandinfoarea.area_aana ||
        !!this.newLandinfoarea.area_aana ||
        !!this.newLandinfoarea.area_daam
      ) {
        areaFt =
          parseFloat(
            !!this.newLandinfoarea.area_ropani
              ? this.nepNumToEng(this.newLandinfoarea.area_ropani)
              : 0
          ) *
            5476.0 +
          parseFloat(
            !!this.newLandinfoarea.area_aana ? this.nepNumToEng(this.newLandinfoarea.area_aana) : 0
          ) *
            342.25 +
          parseFloat(
            !!this.newLandinfoarea.area_paisa
              ? this.nepNumToEng(this.newLandinfoarea.area_paisa)
              : 0
          ) *
            85.5625 +
          parseFloat(
            !!this.newLandinfoarea.area_daam ? this.nepNumToEng(this.newLandinfoarea.area_daam) : 0
          ) *
            21.390625;
      } else if (
        !!this.newLandinfoarea.area_bigha ||
        !!this.newLandinfoarea.area_kattha ||
        !!this.newLandinfoarea.area_dhur
      ) {
        areaFt =
          parseFloat(
            !!this.newLandinfoarea.area_bigha
              ? this.nepNumToEng(this.newLandinfoarea.area_bigha)
              : 0
          ) *
            72900 +
          parseFloat(
            !!this.newLandinfoarea.area_kattha
              ? this.nepNumToEng(this.newLandinfoarea.area_kattha)
              : 0
          ) *
            3645 +
          parseFloat(
            !!this.newLandinfoarea.area_dhur ? this.nepNumToEng(this.newLandinfoarea.area_dhur) : 0
          ) *
            182.25;
      }

      return areaFt;
    },
    areaSqM: function () {
      let areaM = 0;
      if (!!this.newLandinfoarea.area_ropani) {
        areaM =
          parseFloat(
            !!this.newLandinfoarea.area_ropani
              ? this.nepNumToEng(this.newLandinfoarea.area_ropani)
              : 0
          ) *
            508.73704704 +
          parseFloat(
            !!this.newLandinfoarea.area_aana ? this.nepNumToEng(this.newLandinfoarea.area_aana) : 0
          ) *
            31.79606544 +
          parseFloat(
            !!this.newLandinfoarea.area_paisa
              ? this.nepNumToEng(this.newLandinfoarea.area_paisa)
              : 0
          ) *
            7.94901636 +
          parseFloat(
            !!this.newLandinfoarea.area_daam ? this.nepNumToEng(this.newLandinfoarea.area_daam) : 0
          ) *
            1.98725409;
      } else if (!!this.newLandinfoarea.area_bigha) {
        areaM =
          parseFloat(
            !!this.newLandinfoarea.area_bigha
              ? this.nepNumToEng(this.newLandinfoarea.area_bigha)
              : 0
          ) *
            6772.41 +
          parseFloat(
            !!this.newLandinfoarea.area_kattha
              ? this.nepNumToEng(this.newLandinfoarea.area_kattha)
              : 0
          ) *
            338.6319 +
          parseFloat(
            !!this.newLandinfoarea.area_dhur ? this.nepNumToEng(this.newLandinfoarea.area_dhur) : 0
          ) *
            16.93;
      }

      console.log("Area in Meter");
      console.log(`sqftflag: ${this.sqftflag}`);
      console.log(`sqmflag: ${this.sqmflag}`);
      console.log(areaM);
      return areaM;
    },
    bighaDivCheckedFinal: function () {
      if (this.ropaniDivChecked == true) {
        return false;
      } else {
        return this.bighaDivChecked;
      }
    },
    ropaniDivCheckedFinal: function () {
      if (this.bighaDivChecked == true) {
        return false;
      } else {
        return this.ropaniDivChecked;
      }
    },
    sqmToSqft: function () {
      // if ((sqftflag == false || sqftflag == true )&&sqmflag==true) {
      return parseFloat(this.newLandinfoarea.area_sqm * 10.7639);
      // }
      // else{
      // return null;
      // }
    },
    sqftToSqm: function () {
      //   if (sqmflag == false && sqftflag == true) {
      return parseFloat(this.newLandinfoarea.area_sqft / 10.7639);
      //   } else {
      //     return null;
      //   }
    },
  },
  watch: {
    areaSqFt() {
      this.newLandinfoarea.area_sqft = this.areaSqFt.toFixed(2);
    },
    areaSqM() {
      this.newLandinfoarea.area_sqm = this.areaSqM.toFixed(2);
    },
    bighaDivCheckedFinal() {
      this.bighaDivChecked = this.bighaDivCheckedFinal;
    },
    ropaniDivCheckedFinal() {
      // this.bighaDivChecked = !this.ropaniDivChecked;
      this.ropaniDivChecked = this.ropaniDivCheckedFinal;
    },
    sqmToSqft() {
      // if ((sqftflag == false || sqftflag == true) && sqmflag == true) {
      if (!this.sqftflag) {
        this.newLandinfoarea.area_sqft = parseFloat(this.sqmToSqft).toFixed(2);
      }
    },
    sqftToSqm() {
      if (!this.sqmflag) {
        this.newLandinfoarea.area_sqm = parseFloat(this.sqftToSqm).toFixed(3);
      }
    },
  },
  methods: {
    // alertSuccess(message) {
    //   this.$swal("Added!", message, "success");
    // },
    // alertUpdate(message) {
    //   this.$swal("Updated!", message, "success");
    // },
    // alertDelete(message) {
    //   this.$swal("Deleted!", message, "success");
    // },
    alertError(message) {
      this.$swal.fire({
        icon: "error",
        title: message,
        text: "Please have a look on the form!",
      });
    },
    getOldData(oldData) {
      this.landinfoarea = oldData[0];
      this.landinfoarea.id = 0;
      console.log("Emit passed for old data");
    },
    getNibedanAuthorization() {
      this.isPostAuthorized = this.$store.getters.nibedanBibaranAuthData;
    },
    getRegLandUseArea() {
      const url = `/api/registration/${this.$route.params.regId}/application`;
      this.$axios
        .get(url)
        .then((response) => {
          // debugger;
          this.newLandinfoarea.land_use_area_id = response.data.data.land_use_area_id;
        })
        .catch((error) => {
          // alert(2);
          console.log(`api error:${error}`);
        });
    },
    clearAllData() {
      this.newLandinfoarea.id = 0;
      this.newLandinfoarea.reg_id = this.registrationId;
      this.newLandinfoarea.map_sheet_no = "";
      this.newLandinfoarea.land_plot_no = "";
      this.newLandinfoarea.area_ropani = "";
      this.newLandinfoarea.area_aana = "";
      this.newLandinfoarea.area_paisa = "";
      this.newLandinfoarea.area_daam = "";
      this.newLandinfoarea.area_bigha = "";
      this.newLandinfoarea.area_bigha = "";
      this.newLandinfoarea.area_kattha = "";
      this.newLandinfoarea.area_dhur = "";
      this.newLandinfoarea.actual_setback = "";
      this.newLandinfoarea.remarks = "";
      this.newLandinfoarea.land_use_area_id = "";
      this.newLandinfoarea.area_sqm = "";
      this.newLandinfoarea.area_sqft = "";
    },
    save(isNext) {
      // debugger;
      this.isLoading = true;
      if (
        this.newLandinfoarea.id === "null" ||
        this.newLandinfoarea.id === 0 ||
        this.newLandinfoarea.id === undefined
      ) {
        const saveUrl = `/api/registration/${this.registrationId}/land-info-area`;
        this.$axios
          .post(saveUrl, this.newLandinfoarea, {
            timeout: 10000,
          })
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.landinfoarea.push(response.data.data);
              this.isLoading = false;
              this.snackbar.show = true;
              this.successfulMessage = "Successfully Added";
              // this.alertSuccess("Successfully Added !!");

              if (isNext) {
                this.nextStep(1);
              }
              this.$refs.form.reset();
            }
          })
          .catch((error) => {
            // alert(2);
            this.isLoading = false;
            // console.log(`api error:${error}`);
            let message = [];
            let errorResponse = error.response.data.errors;
            for (var i in errorResponse) {
              // errorText = errorText + `${i}: ${errorResponse[i]} \n`;
              message.push(errorResponse[i][0]+"<br>");
            }
            this.alertError(message);
          });
      } else {
        const eurl = `/api/registration/${this.$route.params.regId}/land-info-area/${this.newLandinfoarea.id}`;
        this.$axios
          .put(eurl, this.newLandinfoarea)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              const i = this.landinfoarea.map((item) => item.id).indexOf(this.newLandinfoarea.id); // find index of your object
              this.landinfoarea[i].land_plot_no = this.newLandinfoarea.land_plot_no;
              this.landinfoarea[i].map_sheet_no = this.newLandinfoarea.map_sheet_no;
              this.landinfoarea[i].actual_setback = this.newLandinfoarea.actual_setback;
              // this.landinfoarea[i].designer_name = this.newLandinfoarea.designer_name;
              this.isLoading = false;
              this.snackbar.show = true;
              this.successfulMessage = "Successfully Updated";
              // this.alertUpdate("Successfully Updated !!");

              if (isNext) {
                this.nextStep(1);
              }
              this.$refs.form.reset();
              this.newLandinfoarea.id = 0;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            let message = [];
            let errorResponse = error.response.data.errors;
            for (var i in errorResponse) {
              // errorText = errorText + `${i}: ${errorResponse[i]} \n`;
              message.push(errorResponse[i][0]+"<br>");
            }
            this.alertError(message);
          });
      }
    },
    edit(id) {
      //    const url = `api/registration/${this.$route.params.regId}/designer/${this.$route.params.id}`;
      const url = `api/registration/${this.$route.params.regId}/land-info-area/${id}`;
      this.$axios.get(url).then((response) => {
        // debugger;
        this.newLandinfoarea = response.data.data;
        console.log(response.data.data);
      });
    },
    deleterecord(id) {
      const deleteurl = `api/registration/${this.$route.params.regId}/land-info-area/${id}`;
      this.$axios
        .delete(deleteurl, {
          timeout: 100000,
        })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Successfully Deleted";
            // this.alertDelete("Successfully Deleted !!");

            const i = this.landinfoarea.map((item) => item.id).indexOf(id); // find index of your object
            this.landinfoarea.splice(i, 1);
          }
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleRopaniBigha() {
      this.ropaniDivChecked = this.bighaDivChecked ? false : true;
      this.bighaDivChecked = this.ropaniDivChecked ? false : true;
    },

    // Get the land use area
    getLandUseArea() {
      const apiUrl = "api/land-use-area";
      this.$axios
        .get(apiUrl)
        .then((response) => {
          this.landUseArea = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.custom-text-field,
.v-text-field,
.v-text-field--enclosed,
.v-input__slot {
  padding: 0;
}

.v-text-field__details {
  margin: 0;
  padding: 0;
}

label span {
  color: red;
}

.table-title {
  font-size: 17px;
}
</style>
