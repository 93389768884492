<template>
  <div>
    <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></loading>
    <v-row>
      <v-col cols="auto">
        <v-btn class="primary" :to="{ name: 'application_tab', params: { id: regId } }">Back</v-btn>
      </v-col>
    </v-row>
    <v-stepper v-model="el" class="vstepper stepper-height my-4" v-if="isDocumentListFetched">
      <v-row>
        <v-col cols="3" id="tabBar">
          <v-stepper-header class="stepperHeader">
            <template v-for="n in documentList" class="stepperHeaderTemplate">
              <v-card :key="n.sn" class="sober elevation-12 pt-1 pb-1 stepper-card">
                <v-stepper-step :key="n.sn" :step="n.sn" editable complete class="vstepperStep" style="font-size: 0.9rem">
                  {{ n.docTitleShort }}
                </v-stepper-step>
              </v-card>
            </template>
          </v-stepper-header>
        </v-col>
        <v-col cols="9">
          <v-card>
            <v-stepper-items>
              <v-stepper-content v-for="n in documentList" :key="`${n.sn}-content`" :step="n.sn" vertical>
                <v-card>
                  <v-tabs v-model="tab" background-color="primary" dark>
                    <v-tabs-slider color="yellow"></v-tabs-slider>
                    <v-tab v-for="item in tabItems" :key="item.tab">
                      {{ item }}
                    </v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tab">
                    <v-tab-item>
                      <v-card flat>
                        <!-- <v-card-text>Nice</v-card-text> -->
                        <doc-upload
                          :apiUrl="n.apiUrl"
                          :docTitle="n.docTitle"
                          :file-size="n.fileSize"
                          v-bind:next-step="nextStep"
                          v-if="n.sn === el"
                          :isLastElement="n.sn === documentList.length"
                          :isPostAuthorized="$store.getters.documentsAuthData || uploadLevel(n.nativePhaseId)"
                        ></doc-upload>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <div v-if="$store.getters.registrationData.construction_type_id == 1">
                          <v-card-text>History not available for new registration</v-card-text>
                        </div>
                        <div v-else>
                          <doc-upload
                            :apiUrl="n.apiUrl"
                            :docTitle="n.docTitle"
                            :file-size="n.fileSize"
                            v-bind:next-step="nextStep"
                            v-if="n.sn === el"
                            :isLastElement="n.sn === documentList.length"
                            :isPostAuthorized="$store.getters.documentsAuthData"
                          ></doc-upload>
                        </div>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-card>
        </v-col>
      </v-row>
    </v-stepper>
  </div>
</template>
<script>
import { engNumConvert } from "@/modules/mixins/numericToUnicode.js";
import api from "@/api/api";

export default {
  name: "DocumentUploadTab",
  mixins: [engNumConvert],
  props: {
    id: {
      type: [String, Number],
    },
    regId: {
      type: [String, Number],
    },
    sn: {
      type: [String, Number],
    },
    phaseId: {
      type: [Number, String],
    },
  },
  components: {},
  data() {
    return {
      el: Number(this.sn),
      userRoleId: localStorage.getItem("auth_user_role_id"),
      reg: [],
      formData: new FormData(),
      isDocumentListFetched: false,
      documentList: [],
      tab: null,
      tabItems: ["Current", "History"],
      text: "nice nice",
      isLoading: false,
    };
  },
  computed: {
    constructionTypeIdStr() {
      return this.$store.getters.registrationData.construction_type_id.toString();
    },
  },
  created() {
    this.getDocumentMaster();
  },
  mounted() {
    if (this.sn === undefined) {
      this.sn = 1;
    }
  },
  methods: {
    async getDocumentMaster() {
      this.isLoading = true;
      try {
        let response = await api.documents.phaseDocumentMaster(this.constructionTypeIdStr, this.phaseId);
        let documentData = response.data.data;
        this.documentList = [];
        documentData.forEach((element) => {
          this.documentList.push({
            sn: element.index,
            docTitle: element.document_master.title,
            docTitleShort: `${element.index_np}) ${element.document_master.title_short}`,
            apiUrl: `api/registration/${this.regId}/documents/${element.document_master.document_name}`,
            fileSize: element.document_master.file_size,
            nativePhaseId: element.document_master.native_phase_id,
          });
        });
        this.isDocumentListFetched = true;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    nextStep(n) {
      if (n === this.documentList.sn) {
        this.el = 1;
      } else {
        this.el += n;
      }
    },
    uploadLevel(nativePhaseId) {
      if (parseInt(nativePhaseId) > parseInt(this.phaseId)) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.vstepperStep {
  padding: 15px;
  line-height: 30px;
}

.stepper-card {
  width: 450px;
}

.stepper-height {
  min-height: 1100px;
}

/* @media only screen and (max-width: 600px) {
  .stepper-card {
    position: absolute;
  }

} */
</style>
