<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <label> भवनको तला<span>*</span></label>
      </v-col>

      <v-col cols="6" md="2">
        <v-text-field
          v-model="application.building_storey"
          :rules="requiredFormat('Storey', '^(?:[0-9]+|[०-९]+)$')"
          label="भवनको तला"
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="2">
        <v-text-field
          v-model="application.building_storey_basement"
          label="बेसमेन्टको तला सङ्ख्या"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-for="i in floorLengthBase" :key="i">
      <v-col cols="3"></v-col>
      <v-col cols="1">
        <v-text-field
          readonly
          v-model="floorRevenueBase[i - 1].floor_name"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          label="Area"
          type="number"
          v-model="floorRevenueBase[i - 1].area"
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-text-field
          label="Height (ft)"
          type="number"
          v-model="floorRevenueBase[i - 1].height_ft"
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-text-field
          label="Height (in)"
          type="number"
          v-model="floorRevenueBase[i - 1].height_in"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row v-for="i in floorLength" :key="i">
      <v-col cols="3"></v-col>
      <v-col cols="1">
        <v-text-field
          readonly
          v-model="floorRevenueSurface[i - 1].floor_name"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          label="Area"
          type="number"
          v-model="floorRevenueSurface[i - 1].area"
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-text-field
          label="Height (ft)"
          type="number"
          v-model="floorRevenueSurface[i - 1].height_ft"
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-text-field
          label="Height (in)"
          type="number"
          v-model="floorRevenueSurface[i - 1].height_in"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <label> भुइँ तलाको क्षेत्रफल<span>*</span></label>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          required
          v-model="application.building_plinth_area"
          :rules="requiredFormat('Plinth Area', `${this.$regexList.area}`)"
          label="Plinth Area (sq.ft)"
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <label> कुल भवन क्षेत्रफल<span>*</span></label>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          required
          v-model="application.building_max_area"
          :rules="requiredFormat('Total Area', `${this.$regexList.area}`)"
          label="Total Area (sq.ft)"
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <label> भवनको कुल उचाइ<span>*</span></label>
      </v-col>

      <v-col cols="12" md="2">
        <v-text-field
          required
          v-model="application.building_height_ft"
          :rules="requiredFormat('Height (ft)', `${this.$regexList.length}`)"
          label="feet"
          suffix="ft"
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          required
          v-model="application.building_height_in"
          :rules="requiredFormat('Height (in)', `${this.$regexList.length}`)"
          label="inch"
          suffix="in"
          dense
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Floorinfo",
  props: {},
  data() {
    return {
      required(propertyType) {
        return (v) => !!v || `${propertyType} is required`;
      },
      requiredUnicode(propertyType) {
        return [
          (v) => !!v || `${propertyType} is required`,
          // v => /^\W$/.test(v) || 'nice one'
          (v) =>
            /^[\W]+$/.test(v) ||
            `${propertyType} नेपाली युनिकोडमा लेख्नुहोला ।`,
        ];
      },
      requiredFormat(propertyType, regexexp) {
        var regex = new RegExp(regexexp, "g");
        return [
          (v) => !!v || `${propertyType} is required`,
          (v) => regex.test(v) || `${propertyType}को फर्मेट मिलेन ।`,
          // /^२[०-९]{3}-[०१]?[०-९]-[०-३]?[०-९]$/   ‍=> Date
        ];
      },
      application: {
        building_storey: "",
        building_storey_basement: "",
        building_plinth_area: "",
        building_max_area: "",
        building_height_ft: "",
        building_height_in: "",
      },
      floorRevenue: [],
      floorRevenueBase: [],
      floorRevenueSurface: [],
      floorName: [],
    };
  },
  computed: {
    floorLength() {
      return this.floorRevenueSurface.length;
    },
    floorLengthBase() {
      return this.floorRevenueBase.length;
    },
    // buildingPlinthArea() {
    //   return this.floorRevenueBase[0].area;
    // }
    baseArea() {
      let tempBaseArea = 0;
      this.floorRevenueSurface.forEach((element) => {
        element.floor == 0
          ? (tempBaseArea = parseFloat(
              element.area == "" || element.area == null ? 0 : element.area
            ))
          : 0;
      });
      return tempBaseArea;
    },
    totalArea() {
      let tempTotalArea = 0;
      let tempFloorInfo = [];
      tempFloorInfo = tempFloorInfo.concat(
        this.floorRevenueSurface,
        this.floorRevenueBase
      );
      tempFloorInfo.forEach((element) => {
        tempTotalArea =
          tempTotalArea +
          parseFloat(
            element.area == "" || element.area == null ? 0 : element.area
          );
      });
      // this.application.building_max_area = tempTotalArea;
      return tempTotalArea;
    },
    totalHeight() {
      let tFeet = 0;
      let tInch = 0;
      let tQuotient = 0;
      let tRem = 0;
      this.floorRevenueSurface.forEach((element) => {
        tInch =
          tInch +
          parseFloat(
            element.height_in == "" || element.height_in == null
              ? 0
              : element.height_in
          );

        tFeet =
          tFeet +
          parseFloat(
            element.height_ft == "" || element.height_ft == null
              ? 0
              : element.height_ft
          );
      });
      if (tInch >= 12) {
        tQuotient = Math.floor(tInch / 12);
        tRem = tInch % 12; //Remove tRem Variable later
        tInch = tRem;
        tFeet = tFeet + tQuotient;
      }
      // default 0 is not approved from backend,so doing this for temporary,fix it later, 18th Nov
      if (!tInch) {
        tInch = "0";
      }
      if (!tFeet) {
        tFeet = "0";
      }
      return [tFeet, tInch];
    },
  },
  watch: {
    "application.building_storey": function (val) {
      val = this.nepNumToEng(val);
      this.floorRevenueSurface.splice(0);
      this.getRevenueSurface();
      this.floorRevenueSurface.splice(val);
      // console.log(`floorRlen:${this.floorRevenue.length}`);
      for (let i = this.floorRevenueSurface.length; i < val; i++) {
        this.floorRevenueSurface.push({
          id: 0,
          floor: i,
          area: "",
          height_ft: "",
          height_in: "",
          floor_name: this.floorName.filter((obj) => {
            return obj.floor_id === i.toString();
          })[0].name,
          // floor_name: "oo"
        });
      }
    },
    "application.building_storey_basement": function (val) {
      val = this.nepNumToEng(val);
      this.floorRevenueBase.splice(0);
      this.getRevenueBase();
      this.floorRevenueBase.splice(val);
      // console.log(`floorRlen:${this.floorRevenue.length}`);
      for (let k = this.floorRevenueBase.length; k < val; k++) {
        this.floorRevenueBase.push({
          id: 0,
          floor: -(k + 1),
          area: "",
          height_ft: "",
          height_in: "",
          floor_name: this.floorName.filter((obj) => {
            return obj.floor_id === (-k - 1).toString();
          })[0].name,
        });
      }
    },
    baseArea: function () {
      this.application.building_plinth_area = this.baseArea;
    },
    totalArea: function () {
      this.application.building_max_area = this.totalArea.toFixed(2);
    },
    totalHeight: function () {
      this.application.building_height_ft = this.totalHeight[0];
      this.application.building_height_in = this.totalHeight[1];
    },
  },
  methods: {
      getFloorNames() {
      const url = `api/floor-names`;
      this.$axios
        .get(url)
        .then((response) => {
          this.floorName = response.data.data;
        })
        .catch((error) => {
          console.log(`floor-master-api-error; ${error}`);
        });
    },
    getRevenueSurface() {
      this.floorRevenueSurface.splice(0);
      for (let j = 0; j < this.floorRevenue.length; j++) {
        if (parseInt(this.floorRevenue[j].floor) >= 0) {
          this.floorRevenueSurface.push(this.floorRevenue[j]);
        }
      }
      this.floorRevenueSurface.sort(
        (a, b) => parseInt(a.floor) - parseInt(b.floor)
      );
    },
    getRevenueBase() {
      this.floorRevenueBase.splice(0);
      for (let j = 0; j < this.floorRevenue.length; j++) {
        if (parseInt(this.floorRevenue[j].floor) < 0) {
          this.floorRevenueBase.push(this.floorRevenue[j]);
        }
      }
      this.floorRevenueBase.sort(
        (a, b) => parseInt(a.floor) - parseInt(b.floor)
      );
      console.log(`revenue-base:${this.floorRevenueBase}`);
    },
  },
};
</script>

<style>
label span {
  color: red;
}
</style>