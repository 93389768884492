<template>
  <div class="vld-parent">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>

    <v-card class="sober mb-8 elevation-0">
      <!-- <v-app id="inspire"> -->
      <!-- <v-card class="sober d-flex flex-column"> -->
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title> BLOCK {{ applicationIndex }}</v-toolbar-title>
      </v-toolbar>
      <!-- <h2>निवेदन</h2> -->
      <v-form ref="form" class="mx-4" v-model="valid">
        <v-row>
          <v-col md="4">
            <label> निवेदन मिति(वि.स.)<span>*</span></label>
            <!-- {{id}} -->
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="application.application_date_np" :rules="requiredFormat(
                'निवेदन मिति(वि.स.)',
                `${this.$regexList.nepDate}`
              )
              " label="निवेदन मिति(वि.स.)" class="" clearable ref="dateField"></v-text-field>
          </v-col>
          <!-- <v-col cols="auto">
              <sidebar-profile></sidebar-profile>
            </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="4">
            <label>भू-उपयोग क्षेत्र<span>*</span></label>
          </v-col>
          <v-col cols="4">
            <v-select v-model="application.land_use_area_id" :rules="[required('भू-उपयोग क्षेत्र')]" :items="landUseArea" item-value="id" item-text="name" :key="id" dense></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <label> भवनको प्रयोजन<span>*</span></label>
          </v-col>

          <v-col cols="4">
            <!-- <v-text-field v-model="application.building_use_id" label=""      ></v-text-field> -->

            <v-select v-model="application.building_use_id" :rules="[required('Building Use')]" :items="buildingUse" item-value="id" item-text="building_use_type" :key="id"
              dense></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <label> भवन ऐन अनुसार भवनको वर्गिकरण<span>*</span></label>
          </v-col>

          <v-col md="4">
            <!-- <v-text-field v-model="application.building_category_id" label=""      ></v-text-field>
                                 -->
            <v-select required v-model="application.building_category_id" :rules="[required('Building Category')]" :items="buildingCategory" item-value="id"
              item-text="building_category_type" :key="id" dense></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <label> भवनको संरचना प्रणाली<span>*</span></label>
          </v-col>

          <v-col md="4">
            <v-select required v-model="application.building_structure_id" :rules="[required('Building Structure')]" :items="buildingStructure" item-value="id"
              item-text="building_structure_type" :key="id" dense></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <label> भवनको लम्बाइ<span>*</span></label>
          </v-col>

          <v-col md="2">
            <v-text-field required v-model="application.building_length_ft" :rules="requiredFormat('Length (ft)', '^(?:[0-9]+|[०-९]+)$')" label="feet" dense></v-text-field>
          </v-col>
          <v-col md="2">
            <v-text-field required v-model="application.building_length_in" :rules="requiredFormat('Length (in)', '^(?:[0-9]+|[०-९]+)$')" label="inch" dense></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <label> भवनको चौडाई<span>*</span></label>
          </v-col>

          <v-col md="2">
            <v-text-field required v-model="application.building_breadth_ft" :rules="requiredFormat('Breadth (ft)', '^(?:[0-9]+|[०-९]+)$')" label="feet" dense></v-text-field>
          </v-col>
          <v-col md="2">
            <v-text-field required v-model="application.building_breadth_in" :rules="requiredFormat('Breadth(in)', '^(?:[0-9]+|[०-९]+)$')" label="inch" dense></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <label> भवनको तला<span>*</span></label>
          </v-col>

          <v-col cols="6" md="2">
            <v-text-field v-model="application.building_storey" :rules="requiredFormat('Storey', '^(?:[0-9]+|[०-९]+)$')" label="भवनको तला"></v-text-field>
          </v-col>
          <v-col cols="6" md="2">
            <v-text-field v-model="application.building_storey_basement" label="बेसमेन्टको तला सङ्ख्या"></v-text-field>
          </v-col>
        </v-row>
        <!-- <v-row v-for="i in parseInt(nepNumToEng(application.building_storey_basement))" :key="i">
            <v-col cols="3"></v-col>
            <v-col cols="1">बेसमेन्ट {{ engNumToNep(i.toString()) }} </v-col>
            <v-col cols="2">
              <v-text-field label="Area"></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-text-field label="Height (ft)"></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-text-field label="Height (in)"></v-text-field>
            </v-col>
             :value="'तला ' + engNumToNep(i.toString())"
          </v-row> -->
        <!-- <v-row
            v-for="(i, index) in parseInt(this.nepNumToEng(application.building_storey)) +
              parseInt(this.nepNumToEng(application.building_storey_basement))"
            :key="i"
          >
            {{ i }}, {{index}}
          </v-row> -->
        <v-row v-for="i in floorLengthBase" :key="i">
          <v-col cols="3"></v-col>
          <v-col cols="1">
            <v-text-field readonly v-model="floorRevenueBase[i - 1].floor_name"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field label="Area" type="number" v-model="floorRevenueBase[i - 1].area"></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Height (ft)" type="number" v-model="floorRevenueBase[i - 1].height_ft"></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Height (in)" type="number" v-model="floorRevenueBase[i - 1].height_in"></v-text-field>
          </v-col>
        </v-row>

        <v-row v-for="i in floorLength" :key="i">
          <v-col cols="3"></v-col>
          <v-col cols="1">
            <v-text-field readonly v-model="floorRevenueSurface[i - 1].floor_name"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field label="Area" type="number" v-model="floorRevenueSurface[i - 1].area"></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Height (ft)" type="number" v-model="floorRevenueSurface[i - 1].height_ft"></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Height (in)" type="number" v-model="floorRevenueSurface[i - 1].height_in"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <label> भुइँ तलाको क्षेत्रफल<span>*</span></label>
          </v-col>
          <!-- <v-text-field v-model="application.reg_id" :value="applicationrole.id" type="hidden" ></v-text-field> -->
          <v-col cols="12" md="4">
            <v-text-field required v-model="application.building_plinth_area" :rules="requiredFormat('Plinth Area', `${this.$regexList.area}`)" label="Plinth Area (sq.ft)"
              dense></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <label> कुल भवन क्षेत्रफल<span>*</span></label>
          </v-col>
          <!-- <v-text-field v-model="application.reg_id" :value="applicationrole.id" type="hidden" ></v-text-field> -->
          <v-col cols="12" md="4">
            <v-text-field required v-model="application.building_max_area" :rules="requiredFormat('Total Area', `${this.$regexList.area}`)" label="Total Area (sq.ft)"
              dense></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <label> भवनको कुल उचाइ<span>*</span></label>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field required v-model="application.building_height_ft" :rules="requiredFormat('Height (ft)', `${this.$regexList.length}`)
              " label="feet" suffix="ft" dense></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field required v-model="application.building_height_in" :rules="requiredFormat('Height (in)', `${this.$regexList.length}`)
              " label="inch" suffix="in" dense></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-btn primary v-on:click="save(false)" v-if="isPostAuthorized" class="primary" :disabled="!valid">Save</v-btn>
            <v-btn primary v-on:click="save(true)" v-if="isPostAuthorized" class="primary ml-1" :disabled="!valid">Save &amp; Next</v-btn>
            <v-btn class="ma-1" color="primary" v-on:click="nextStep(1)">
              Skip
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <!-- <custom-dialog
              click-type="delete"
              :click-dialog="deleteForm(application.id)"
              >Delete</custom-dialog
            > -->
          </v-col>
        </v-row>
      </v-form>

      <v-snackbar v-model="snackbar.show" :bottom="y === 'bottom'" :color="snackbar.color" :left="x === 'left'" :multi-line="mode === 'multi-line'" :right="x === 'right'"
        :timeout="3000" :top="y === 'top'" :vertical="mode === 'vertical'">
        {{ successfulMessage }}
      </v-snackbar>
      <!-- </v-card> -->
      <!-- </v-app> -->
    </v-card>
  </div>
</template>

<script>
import * as nepaliDatePicker from "../../../../node_modules/nepali-date-picker/dist/jquery.nepaliDatePicker.min.js"; /*eslint-disable-line*/
import "../../../../node_modules/nepali-date-picker/dist/nepaliDatePicker.min.css";
import { engNumConvert } from "../../mixins/numericToUnicode.js";
import SidebarProfile from "../../applicationlevel/components/SidebarProfile.vue";

export default {
  name: "Application",
  mixins: [engNumConvert],
  components: {
    // SidebarProfile,
  },
  props: {
    nextStep: {
      type: Function,
    },
    registrationId: {
      type: [String, Number],
    },
    id: {
      type: [String, Number],
    },
    isPostAuthorized: {
      type: Boolean,
    },
    applicationIndex: [String, Number],
    applicationId: [String, Number],
  },
  data() {
    return {
      valid: false,
      required(propertyType) {
        return (v) => !!v || `${propertyType} is required`;
      },
      requiredUnicode(propertyType) {
        return [
          (v) => !!v || `${propertyType} is required`,
          // v => /^\W$/.test(v) || 'nice one'
          (v) =>
            /^[\W]+$/.test(v) ||
            `${propertyType} नेपाली युनिकोडमा लेख्नुहोला ।`,
        ];
      },
      requiredFormat(propertyType, regexexp) {
        var regex = new RegExp(regexexp, "g");
        return [
          (v) => !!v || `${propertyType} is required`,
          (v) => regex.test(v) || `${propertyType}को फर्मेट मिलेन ।`,
          // /^२[०-९]{3}-[०१]?[०-९]-[०-३]?[०-९]$/   ‍=> Date
        ];
      },
      isFormSubmitting: true,
      date: "",
      isValid: true,
      userRoleId: localStorage.getItem("auth_user_role_id"),
      buildingUse: [],
      buildingCategory: [],
      buildingStructure: [],
      application: {
        id: 0,
        // reg_id: 0,
        application_date: "2020-04-22",
        application_date_np: "",
        land_use_area_id: "",
        building_category_id: "",
        building_use_id: "",
        building_structure_id: "",
        building_length_in: "",
        building_breadth_in: "",
        building_height_in: "",
        building_length_ft: "",
        building_breadth_ft: "",
        building_height_ft: "",
        building_storey: "",
        building_storey_basement: "",
        building_max_area: "",
        building_plinth_area: "",
        block: null,
      },
      floorRevenue: [],
      // floorRevenueCopy: [],
      floorRevenueBase: [],
      floorRevenueSurface: [],
      floorInfo: {},
      // floorLength: null,

      floorName: [],
      landUseArea: [],

      // applicationrole: [],
      // applicationList: [],
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
    };
  },
  computed: {
    isLoading() {
      if (
        this.buildingUse.length === 0 ||
        this.buildingCategory.length === 0 ||
        this.buildingStructure.length === 0 ||
        this.isFormSubmitting === false
      ) {
        // all drop-down values not initiated from api
        return true;
      }
      return false;
    },
    floorLength() {
      return this.floorRevenueSurface.length;
    },
    floorLengthBase() {
      return this.floorRevenueBase.length;
    },
    // buildingPlinthArea() {
    //   return this.floorRevenueBase[0].area;
    // }
    baseArea() {
      let tempBaseArea = 0;
      this.floorRevenueSurface.forEach((element) => {
        element.floor == 0
          ? (tempBaseArea = parseFloat(
            element.area == "" || element.area == null ? 0 : element.area
          ))
          : 0;
      });
      return tempBaseArea;
    },
    totalArea() {
      let tempTotalArea = 0;
      let tempFloorInfo = [];
      tempFloorInfo = tempFloorInfo.concat(
        this.floorRevenueSurface,
        this.floorRevenueBase
      );
      tempFloorInfo.forEach((element) => {
        tempTotalArea =
          tempTotalArea +
          parseFloat(
            element.area == "" || element.area == null ? 0 : element.area
          );
      });
      // this.application.building_max_area = tempTotalArea;
      return tempTotalArea;
    },
    totalHeight() {
      let tFeet = 0;
      let tInch = 0;
      let tQuotient = 0;
      let tRem = 0;
      this.floorRevenueSurface.forEach((element) => {
        tInch =
          tInch +
          parseFloat(
            element.height_in == "" || element.height_in == null
              ? 0
              : element.height_in
          );

        tFeet =
          tFeet +
          parseFloat(
            element.height_ft == "" || element.height_ft == null
              ? 0
              : element.height_ft
          );
      });
      if (tInch >= 12) {
        tQuotient = Math.floor(tInch / 12);
        tRem = tInch % 12; //Remove tRem Variable later
        tInch = tRem;
        tFeet = tFeet + tQuotient;
      }
      // default 0 is not approved from backend,so doing this for temporary,fix it later, 18th Nov
      if (!tInch) {
        tInch = "0";
      }
      if (!tFeet) {
        tFeet = "0";
      }
      return [tFeet, tInch];
    },
  },
  watch: {
    "application.building_storey": function (val) {
      val = this.nepNumToEng(val);
      this.floorRevenueSurface.splice(0);
      this.getRevenueSurface();
      this.floorRevenueSurface.splice(val);
      // console.log(`floorRlen:${this.floorRevenue.length}`);
      for (let i = this.floorRevenueSurface.length; i < val; i++) {
        this.floorRevenueSurface.push({
          id: 0,
          application_id: 0, // New
          block: this.applicationIndex, // New
          floor: i,
          area: "",
          height_ft: "",
          height_in: "",
          floor_name: this.floorName.filter((obj) => {
            return obj.floor_id === i.toString();
          })[0].name,
          // floor_name: "oo"
        });
      }
    },
    "application.building_storey_basement": function (val) {
      val = this.nepNumToEng(val);
      this.floorRevenueBase.splice(0);
      this.getRevenueBase();
      this.floorRevenueBase.splice(val);
      // console.log(`floorRlen:${this.floorRevenue.length}`);
      for (let k = this.floorRevenueBase.length; k < val; k++) {
        this.floorRevenueBase.push({
          id: 0,
          application_id: 0, // New
          block: this.applicationIndex, // New
          floor: -(k + 1),
          area: "",
          height_ft: "",
          height_in: "",
          floor_name: this.floorName.filter((obj) => {
            return obj.floor_id === (-k - 1).toString();
          })[0].name,
        });
      }
    },
    baseArea: function () {
      this.application.building_plinth_area = this.baseArea;
    },
    totalArea: function () {
      this.application.building_max_area = this.totalArea.toFixed(2);
    },
    totalHeight: function () {
      this.application.building_height_ft = this.totalHeight[0];
      this.application.building_height_in = this.totalHeight[1];
    },
    buildingUseList() {
      // To dynamically load भवनको प्रयोजन (building_use) in dynamic dropdown
      let buildingUseListTemp = [];
      if (this.landBuilding.length) {
        for (let i = 0; i < this.landBuilding.length; i++) {
          if (
            this.application.land_use_area_id ==
            this.landBuilding[i].land_use_area_id
          ) {
            buildingUseListTemp.push(this.landBuilding[i].building_use);
          }
        }
      } else {
        buildingUseListTemp = this.buildingUse;
      }
      return buildingUseListTemp;
    },
  },
  created() {
    this.buildingUseCall();
    this.buildingCategoryCall();
    this.buildingStructureCall();
    this.applicationDetailCall();
    this.floorRevenueCall();
    // this.getRole();
    this.getDate();
    this.getLandUseArea();
  },
  mounted() {
    const { dateField } = this.$refs;
    if (dateField) {
      $(dateField.$el).nepaliDatePicker({
        dateFormat: "%y-%m-%d",
        closeOnDateSelect: true,
      });
      $(dateField.$el).on("dateSelect", (event) => {
        console.log(`Date: ${event.datePickerData.formattedDate}`);
        this.application.application_date_np =
          event.datePickerData.formattedDate;
      });
    }
    // setFloorName(() => {
    //   this.floorRevenue[i].floor = "तला " + engNumToNep(i.toString());
    // });
  },
  methods: {
    getRevenueSurface() {
      this.floorRevenueSurface.splice(0);
      for (let j = 0; j < this.floorRevenue.length; j++) {
        if (parseInt(this.floorRevenue[j].floor) >= 0) {
          this.floorRevenueSurface.push(this.floorRevenue[j]);
        }
      }
      this.floorRevenueSurface.sort(
        (a, b) => parseInt(a.floor) - parseInt(b.floor)
      );
    },
    getRevenueBase() {
      this.floorRevenueBase.splice(0);
      for (let j = 0; j < this.floorRevenue.length; j++) {
        if (parseInt(this.floorRevenue[j].floor) < 0) {
          this.floorRevenueBase.push(this.floorRevenue[j]);
        }
      }
      this.floorRevenueBase.sort(
        (a, b) => parseInt(a.floor) - parseInt(b.floor)
      );
      console.log(`revenue-base:${this.floorRevenueBase}`);
    },
    getDate() {
      const dateUrl = "api/current-date";
      this.$axios
        .get(dateUrl)
        .then((response) => {
          this.application.application_date_np = response.data.date_np;
          this.application.application_date = response.data.date;
        })
        .catch(() => { });
    },
    applicationDetailCall() {
      const url = `api/registration/${this.registrationId}/application/${this.applicationId}`;
      this.$axios
        .get(url)
        .then((response) => {
          // debugger;
          this.application = response.data.data;
          // add building storey basement in db later
          // this.application.building_storey_basement = "2";
          // console.log(this.application.building_storey_basement);
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
    },
    floorRevenueCall() {
      const url = `api/registration/${this.registrationId}/application/${this.applicationId}/floor-info-revenue`;
      this.$axios
        .get(url)
        .then((response) => {
          // debugger;
          this.floorRevenue = response.data.data[0].floorRevenue;
          // this.floorRevenueCopy = response.data[0].floorRevenue;
          // this.separateSurfaceAndBase();
          // this.getRevenueSeparate();
          this.getRevenueSurface();
          this.getRevenueBase();
          this.getFloorNames();

          // console.log(`floorRevenue:${this.floorRevenueCopy}`);
          // console.log(this.floorRevenue[0].floor);
        })
        .catch((error) => {
          console.log(`floor api error:${error}`);
          this.getFloorNames();
        });
    },
    getFloorNames() {
      const url = `api/floor-names`;
      this.$axios
        .get(url)
        .then((response) => {
          this.floorName = response.data.data;
        })
        .catch((error) => {
          console.log(`floor-master-api-error; ${error}`);
        });
    },
    getLandUseArea() {
      const apiUrl = `api/land-use-area`;
      this.$axios
        .get(apiUrl)
        .then((response) => {
          this.landUseArea = response.data.data;
          this.buildingUseCall();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    buildingUseCall() {
      const buildingUseUrl = "api/building-use";
      this.$axios
        .get(buildingUseUrl)
        .then((response) => {
          this.buildingUse = response.data;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
    },
    buildingCategoryCall() {
      const buildingCategoryUrl = "api/building-category";
      this.$axios
        .get(buildingCategoryUrl)
        .then((response) => {
          this.buildingCategory = response.data;
          // console.log(this.buildingCategory);
          // debugger;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
    },
    buildingStructureCall() {
      const buildingStructureUrl = "api/building-structure";
      this.$axios
        .get(buildingStructureUrl, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          this.buildingStructure = response.data;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
    },
    save(isNext) {
      this.isFormSubmitting = false;
      if (
        this.application.id === null ||
        this.application.id === 0 ||
        this.application.id === undefined
      ) {
        this.application.block = this.applicationIndex;
        const url = `/api/registration/${this.$route.params.regId}/application`;
        this.$axios
          .post(url, this.application)
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.isFormSubmitting = true;
              this.application = response.data.data;
              this.snackbar.show = true;
              this.successfulMessage = "Successful.";
              this.saveFloorInfo(this.application.id);
              if (isNext) {
                this.nextStep(1);
              }
            }
          })
          .catch((error) => {
            // alert(2);
            console.log(`api error:${error}`);
          });
      } else {
        this.application.block = this.applicationIndex;
        const eurl = `/api/registration/${this.$route.params.regId}/application/${this.application.id}`;
        this.$axios
          .put(eurl, this.application)
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.isFormSubmitting = true;
              this.snackbar.show = true;
              this.successfulMessage = "Data Updated successfully";
              this.saveFloorInfo(this.application.id);
              if (isNext) {
                this.nextStep(1);
              }
            }
            // else {
            //     this.$router.push({
            //         name: 'tabbed',
            //     });
            // }
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    edit(id) {
      // debugger;
      //    const url = `api/registration/${this.$route.params.regId}/application/${this.$route.params.id}`;
      const url = `api/registration/${this.$route.params.regId}/application/${id}`;
      this.$axios.get(url).then((response) => {
        // debugger;
        this.application = response.data.data;
        console.log(response.data.data);
      });
    },
    deleterecord(id) {
      if (id == 0) {
        //
      } else {
        const deleteurl = `api/registration/${this.$route.params.regId}/application/${id}`;
        this.$axios
          .delete(deleteurl, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.snackbar.show = true;
              this.successfulMessage = "Data deleted successfully";
            }
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    saveFloorInfo(application_id) {
      this.floorInfo = this.floorRevenueSurface;
      this.floorInfo = this.floorInfo.concat(this.floorRevenueBase); // this is not working, check it later
      this.floorInfo.forEach((item) => {
        item.application_id = application_id;
      });
      if (
        this.floorInfo[0].id === null ||
        this.floorInfo[0].id === 0 ||
        this.floorInfo[0].id === undefined
      ) {
        const url = `api/registration/${this.registrationId}/floor-info-revenue`;
        this.$axios
          .post(url, { floorRevenue: this.floorInfo })
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.isFormSubmitting = true;
              this.floorRevenue = response.data.data;
              this.snackbar.show = true;
              this.successfulMessage = "Successful.";
            }
          })
          .catch((error) => {
            // alert(2);
            console.log(`api error:${error}`);
          });
      } else {
        const eurl = `api/registration/${this.registrationId}/floor-info-revenue/${this.floorRevenue[0].id}`;
        this.$axios
          .put(eurl, { floorRevenue: this.floorInfo })
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.isFormSubmitting = true;
              this.snackbar.show = true;
              this.successfulMessage = "Data Updated successfully";
            }
            // else {
            //     this.$router.push({
            //         name: 'tabbed',
            //     });
            // }
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    deleteForm(id) {
      this.$emit("delete", id);
    },
  },
};
</script>

<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 58px;
}

.custom-text-field,
.v-text-field,
.v-text-field--enclosed,
.v-input__slot {
  padding: 0px;
}

/* .v-text-field__details {
margin: 0 !important;
padding: 5px !important;
} */
label span {
  color: red;
}

/* .v-text-field__details {
    position: absolute !important;
    margin: 12px 100px!important;
} */
.error-message {
  padding: 10px !important;
}
</style>
