<template>
  <div class="vld-parent">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>

    <v-card class="sober mb-8 elevation-0">
      <!-- <v-app id="inspire"> -->
      <!-- <v-card class="sober d-flex flex-column"> -->
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title> पर्खालको विवरण </v-toolbar-title>
      </v-toolbar>
      <!-- <h2>निवेदन</h2> -->
      <v-form ref="form" class="mx-4" v-model="valid">
        <v-row class="mt-2">
          <v-col md="4">
            <label> निवेदन मिति(वि.स.)<span>*</span></label>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="application.application_date_np"
              :rules="
                requiredFormat(
                  'निवेदन मिति(वि.स.)',
                  `${this.$regexList.nepDate}`
                )
              "
              label="निवेदन मिति(वि.स.)"
              class=""
              clearable
              ref="dateField"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <label>भू-उपयोग क्षेत्र<span>*</span></label>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="application.land_use_area_id"
              :rules="[required('भू-उपयोग क्षेत्र')]"
              :items="landUseArea"
              item-value="id"
              item-text="name"
              :key="id"
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <label> पर्खालको लम्बाइ<span>*</span></label>
          </v-col>

          <v-col md="2">
            <v-text-field
              required
              v-model="application.building_length_ft"
              :rules="requiredFormat('Length (ft)', '^(?:[0-9]+|[०-९]+)$')"
              label="feet"
              suffix="ft"
              dense
            ></v-text-field>
          </v-col>
          <v-col md="2">
            <v-text-field
              required
              v-model="application.building_length_in"
              :rules="requiredFormat('Length (in)', '^(?:[0-9]+|[०-९]+)$')"
              label="inch"
              suffix="in"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <label> पर्खालको चौडाई<span>*</span></label>
          </v-col>

          <v-col md="2">
            <v-text-field
              required
              v-model="application.building_breadth_ft"
              :rules="requiredFormat('Breadth (ft)', '^(?:[0-9]+|[०-९]+)$')"
              label="feet"
              suffix="ft"
              dense
            ></v-text-field>
          </v-col>
          <v-col md="2">
            <v-text-field
              required
              v-model="application.building_breadth_in"
              :rules="requiredFormat('Breadth(in)', '^(?:[0-9]+|[०-९]+)$')"
              label="inch"
              suffix="in"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <label> पर्खालको उचाइ<span>*</span></label>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field
              required
              v-model="application.building_height_ft"
              :rules="
                requiredFormat('Height (ft)', `${this.$regexList.length}`)
              "
              label="feet"
              suffix="ft"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              required
              v-model="application.building_height_in"
              :rules="
                requiredFormat('Height (in)', `${this.$regexList.length}`)
              "
              label="inch"
              suffix="in"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              primary
              v-on:click="save(false)"
              v-if="isPostAuthorized"
              class="primary"
              :disabled="!valid"
              >Save</v-btn
            >
            <v-btn
              primary
              v-on:click="save(true)"
              v-if="isPostAuthorized"
              class="primary ml-1"
              :disabled="!valid"
              >Save &amp; Next</v-btn
            >
            <v-btn class="ma-1" color="primary" v-on:click="nextStep(1)">
              Skip
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <br /><br />

      <v-snackbar
        v-model="snackbar.show"
        :bottom="y === 'bottom'"
        :color="snackbar.color"
        :left="x === 'left'"
        :multi-line="mode === 'multi-line'"
        :right="x === 'right'"
        :timeout="3000"
        :top="y === 'top'"
        :vertical="mode === 'vertical'"
      >
        {{ successfulMessage }}
      </v-snackbar>
      <!-- </v-card> -->
      <!-- </v-app> -->
    </v-card>
  </div>
</template>

<script>
import * as nepaliDatePicker from "../../../../node_modules/nepali-date-picker/dist/jquery.nepaliDatePicker.min.js"; /*eslint-disable-line*/
import "../../../../node_modules/nepali-date-picker/dist/nepaliDatePicker.min.css";
import { engNumConvert } from "../../mixins/numericToUnicode.js";
import SidebarProfile from "../../applicationlevel/components/SidebarProfile.vue";
import FormHistory from "../../history/FormHistory.vue";

export default {
  name: "Application",
  mixins: [engNumConvert],
  components: {
    // SidebarProfile,
    // FormHistory,
  },
  props: {
    nextStep: {
      type: Function,
    },
    registrationId: {
      type: [String, Number],
    },
    id: {
      type: [String, Number],
    },
    // isPostAuthorized: {
    //   type: Boolean,
    // },
    regConstructionId: {
      type: Number,
    },
  },
  data() {
    return {
      valid: false,

      required(propertyType) {
        return (v) => !!v || `${propertyType} is required`;
      },
      requiredUnicode(propertyType) {
        return [
          (v) => !!v || `${propertyType} is required`,
          // v => /^\W$/.test(v) || 'nice one'
          (v) =>
            /^[\W]+$/.test(v) ||
            `${propertyType} नेपाली युनिकोडमा लेख्नुहोला ।`,
        ];
      },
      requiredFormat(propertyType, regexexp) {
        var regex = new RegExp(regexexp, "g");
        return [
          (v) => !!v || `${propertyType} is required`,
          (v) => regex.test(v) || `${propertyType}को फर्मेट मिलेन ।`,
          // /^२[०-९]{3}-[०१]?[०-९]-[०-३]?[०-९]$/   ‍=> Date
        ];
      },
      isFormSubmitting: true,
      date: "",
      isValid: true,
      userRoleId: localStorage.getItem("auth_user_role_id"),
      buildingUse: [],
      buildingCategory: [],
      buildingStructure: [],
      application: {
        id: 0,
        // reg_id: 0,
        application_date: "2020-04-22",
        application_date_np: "",
        building_category_id: "",
        land_use_area_id: "",
        building_use_id: "",
        building_structure_id: "",
        building_length_in: "",
        building_breadth_in: "",
        building_height_in: "",
        building_length_ft: "",
        building_breadth_ft: "",
        building_height_ft: "",
        building_storey: "",
        building_storey_basement: "",
        building_max_area: "",
        building_plinth_area: "",
      },
      floorRevenue: [],
      // floorRevenueCopy: [],
      floorRevenueBase: [],
      floorRevenueSurface: [],
      floorInfo: {},
      // floorLength: null,

      floorName: [],

      // applicationrole: [],
      // applicationList: [],
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },

      // land use area array
      landUseArea: [],
      landBuilding: [],
      buildingUseList: [],
      isPostAuthorized: false,
    };
  },
  computed: {
    isLoading() {
      if (this.landUseArea.length === 0 || this.isFormSubmitting === false) {
        // all drop-down values not initiated from api
        return true;
      }
      return false;
    },
    floorLength() {
      return this.floorRevenueSurface.length;
    },
    floorLengthBase() {
      return this.floorRevenueBase.length;
    },
  },
  watch: {},
  created() {
    // this.buildingUseCall();
    // this.buildingCategoryCall();
    // this.buildingStructureCall();
    // this.getLandBuilding(); // to get landbuiding pivot table
    // this.floorRevenueCall();
    // this.getDate();
    this.getLandUseArea(); // to get land use area for भू-उपयोग क्षेत्र
    this.getNibedanAuthorization();
  },
  mounted() {
    this.applicationDetailCall();

    const { dateField } = this.$refs;
    if (dateField) {
      $(dateField.$el).nepaliDatePicker({
        dateFormat: "%y-%m-%d",
        closeOnDateSelect: true,
      });
      $(dateField.$el).on("dateSelect", (event) => {
        console.log(`Date: ${event.datePickerData.formattedDate}`);
        this.application.application_date_np =
          event.datePickerData.formattedDate;
      });
    }
  },
  methods: {
    

    getNibedanAuthorization() {
      const authorizeUrl = `api/registration/${this.registrationId}/nibedan-bibaran/is-authorized`;
      this.$axios
        .get(authorizeUrl)
        .then((response) => {
          this.isPostAuthorized = response.data.data;
        })
        .catch((error) => {
          console.log(`Nibedan Authorization Error: ${error}`);
        });
    },
    getDate() {
      const dateUrl = "api/current-date";
      this.$axios
        .get(dateUrl)
        .then((response) => {
          this.application.application_date_np = response.data.date_np;
          this.application.application_date = response.data.date;
        })
        .catch(() => {});
    },
    applicationDetailCall() {
      const url = `api/registration/${this.registrationId}/application`;
      this.$axios
        .get(url)
        .then((response) => {
          // debugger;
          this.application = response.data.data;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
    },
    getLandUseArea() {
      const apiUrl = `api/land-use-area`;
      this.$axios
        .get(apiUrl)
        .then((response) => {
          this.landUseArea = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    save(isNext) {
    //   this.saveFloorInfo();
      this.isFormSubmitting = false;
      if (
        this.application.id === null ||
        this.application.id === 0 ||
        this.application.id === undefined
      ) {
        const url = `/api/registration/${this.$route.params.regId}/application`;
        this.$axios
          .post(url, this.application)
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.isFormSubmitting = true;
              this.application = response.data.data;
              this.snackbar.show = true;
              this.successfulMessage = "Successful.";
              if (isNext) {
                this.nextStep(1);
              }
            }
          })
          .catch((error) => {
            // alert(2);
            console.log(`api error:${error}`);
          });
      } else {
        const eurl = `/api/registration/${this.$route.params.regId}/application/${this.application.id}`;
        this.$axios
          .put(eurl, this.application)
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.isFormSubmitting = true;
              this.snackbar.show = true;
              this.successfulMessage = "Data Updated successfully";
              if (isNext) {
                this.nextStep(1);
              }
            }
            // else {
            //     this.$router.push({
            //         name: 'tabbed',
            //     });
            // }
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    edit(id) {
      // debugger;
      //    const url = `api/registration/${this.$route.params.regId}/application/${this.$route.params.id}`;
      const url = `api/registration/${this.$route.params.regId}/application/${id}`;
      this.$axios.get(url).then((response) => {
        // debugger;
        this.application = response.data.data;
        console.log(response.data.data);
      });
    },
    deleterecord(id) {
      const deleteurl = `api/registration/${this.$route.params.regId}/application/${id}`;
      this.$axios
        .delete(deleteurl, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          // debugger;
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Data deleted successfully";
          }
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 58px;
}

.custom-text-field,
.v-text-field,
.v-text-field--enclosed,
.v-input__slot {
  padding: 0px;
}
/* .v-text-field__details {
margin: 0 !important;
padding: 5px !important;
} */
label span {
  color: red;
}
/* .v-text-field__details {
    position: absolute !important;
    margin: 12px 100px!important;
} */
.error-message {
  padding: 10px !important;
}
</style>
