<template>
  <div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <v-card class="sober d-flex flex-column">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title> डिजाइनर </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="mx-4 my-4" v-model="valid">
        <v-row>
          <v-col cols="4" md="3">
            <label>Affiliation Type<span> *</span></label>
          </v-col>
          <v-col md="4">
            <v-radio-group dense v-model="newDesigner.is_consulting_firm" :mandatory="true">
              <v-radio label="Consulting Firm" v-bind:value="1"></v-radio>
              <v-radio label="Freelancer" v-bind:value="0"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="newDesigner.is_consulting_firm" class="mt-4">
          <v-col cols="6" md="3"> आवद्ध कन्सल्टेन्सी </v-col>
          <v-col cols="6" md="4">
            <v-select
              v-model="newDesigner.affiliated_consultancy_id"
              label="Select आवद्ध कन्सल्टेन्सी"
              :items="consultancy"
              item-value="id"
              item-text="name"
              @change="consultancyDetail()"
              :key="id"
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col cols="6" md="3">
            <label> Consultancy's Municipality Registration No</label>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model="newDesigner.registration_number"
              label="Consultancy's Registration No"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="newDesigner.is_consulting_firm">
          <v-col cols="6" md="3">
            <label for="designer-type">Designer Type<span>*</span></label></v-col
          >
          <v-col cols="5" md="4">
            <v-select
              v-model="newDesigner.designer_type_id"
              label="Select"
              :items="designerTypes"
              item-value="id"
              item-text="name_en"
              :key="id"
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row hidden>
          <v-col cols="6" md="3"> आवद्ध कन्सल्टेन्सी </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model="newDesigner.affiliated_consultancy_name"
              label="आवद्ध कन्सल्टेन्सी"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3">
            <label for="">Designer's Council No<span> *</span></label>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model="newDesigner.designer_council_number"
              label="Designer's Council No"
              dense
              :rules="[required('Council No')]"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" md="3">
            <label> डिजाइनरको नाम <span> *</span></label>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model="newDesigner.designer_name"
              :rules="requiredFormat('Designer Name', `${this.$regexList.nepName}`)"
              label="डिजाइनरको नाम"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3">
            <label> Designer Email <span> *</span></label>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model="newDesigner.email"
              :rules="required | email"
              label="Designer email"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3">
            <label> Designer Phone <span> *</span></label>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model="newDesigner.phone_no"
              :rules="requiredPhone('Phone Number')"
              label="Designer Phone"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- <v-snackbar
          v-model="snackbar.show"
          :bottom="y === 'bottom'"
          :color="snackbar.color"
          :left="x === 'left'"
          :multi-line="mode === 'multi-line'"
          :right="x === 'right'"
          :timeout="3000"
          :top="y === 'top'"
          :vertical="mode === 'vertical'"
        >
          {{ successfulMessage }}
        </v-snackbar> -->
        <v-row>
          <v-col class="my-4">
            <v-btn
              primary
              v-if="isPostAuthorized"
              v-on:click="save(false)"
              class="primary"
              :disabled="!valid"
              >Save
            </v-btn>
            <v-btn
              primary
              v-on:click="save(true)"
              v-if="isPostAuthorized"
              class="primary ml-1"
              :disabled="!valid"
              >Save &amp; Next</v-btn
            >
            <v-btn class="mx-2 my-2" color="primary" @click="nextStep(1)"> Skip </v-btn>
          </v-col>
        </v-row>
        <br /><br />
      </v-form>
      <v-toolbar color="primary mt-2" dark flat>
        <v-toolbar-title> List </v-toolbar-title>
      </v-toolbar>
      <v-simple-table class="sober">
        <template v-slot:default>
          <thead>
            <tr class="registrationtable">
              <th class="text-left registrationtable font-18">आवद्ध कन्सल्टेन्सी</th>
              <th class="text-left registrationtable font-18">
                Designer's Municipality Registration Number
              </th>
              <th class="text-left registrationtable font-18">Designer's Council No</th>
              <th class="text-left registrationtable font-18">Designer's Name</th>
              <th class="text-left registrationtable font-18">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr class="registrationtable" v-for="designerList in designer" :key="designerList.id">
              <td class="registrationtable">
                {{ designerList.affiliated_consultancy_name }}
              </td>
              <td class="registrationtable">
                {{ designerList.registration_number }}
              </td>
              <td class="registrationtable">
                {{ designerList.designer_council_number }}
              </td>
              <td class="registrationtable">
                {{ designerList.designer_name }}
              </td>
              <td>
                <v-btn
                  small
                  class="ma-1"
                  color="primary"
                  v-if="isPostAuthorized"
                  v-on:click="edit(designerList.id)"
                  >Edit</v-btn
                >
                <!-- <v-btn
                  small
                  color="primary"
                  v-if="isPostAuthorized"
                  v-on:click="deleterecord(designerList.id)"
                  >Delete</v-btn
                > -->
                <custom-dialog
                  v-if="isPostAuthorized"
                  :click-dialog="deleterecord"
                  :regId="designerList.id"
                  click-type="delete"
                  >Delete</custom-dialog
                >
                <v-btn small color="primary" v-else v-on:click="edit(designerList.id)">View</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <form-history :historyData="historyData"></form-history>
  </div>
</template>
<script>
import FormHistory from "../../history/FormHistory.vue";

export default {
  name: "Designer",
  props: {
    nextStep: {
      type: Function,
    },
    registrationId: {
      type: [String, Number],
    },
    id: {
      type: [String, Number],
    },
    application: {
      type: Object,
    },
    // isPostAuthorized: {
    //   type: Boolean,
    // },
    regConstructionId: {
      type: Number,
    },
  },

  components: {
    FormHistory,
  },

  data() {
    return {
      valid: false,
      same_as_old_data: false,
      historyData: {
        apiUrl: `api/registration/${this.registrationId}/designer/history`,
        formData: [
          {
            title: "आवद्ध कन्सल्टेन्सी",
            key: "affiliated_consultancy_name",
          },
          {
            title: "डिजाइनरको नाम ",
            key: "designer_name",
          },
          {
            title: "Designer's Council No",
            key: "designer_council_number",
          },
          {
            title: "Phone",
            key: "phone_no",
          },
        ],
      },
      required(propertyType) {
        return (v) => !!v || `${propertyType} is required`;
      },
      requiredFormat(propertyType, regexexp) {
        var regex = new RegExp(regexexp, "g");
        return [
          (v) => !!v || `${propertyType} is required`,
          (v) => regex.test(v) || `${propertyType}को फर्मेट मिलेन ।`,
          // /^२[०-९]{3}-[०१]?[०-९]-[०-३]?[०-९]$/   ‍=> Date
        ];
      },
      requiredPhone(propertyType) {
        return [
          (v) => !!v || `${propertyType} is required`,
          // v => /^\w$/ || 'Please write in english format',
          (v) =>
            /^\+?(977|९७७)? ?[0-9०-९]{9,15}$/.test(v) ||
            `${propertyType} is not valid phone number`,
        ];
      },
      date: "",
      designer: [],
      consultancy: [],
      results: [],
      userRoleId: localStorage.getItem("auth_user_role_id"),
      newDesigner: {
        id: 0,
        reg_id: this.registrationId,
        is_consulting_firm: "",
        registration_number: "",
        affiliated_consultancy: "",
        affiliated_consultancy_id: "",
        affiliated_consultancy_name: "",
        designer_type_id: "",
        designer_name: "",
        designer_council_number: "",
        phone_no: "",
        email: "",
      },
      isValid: true,
      isLoading: true,
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      designerTypes: [],
      isPostAuthorized: false,
    };
  },
  created() {
    this.getNibedanAuthorization();
  },
  mounted() {
    const url = `api/registration/${this.registrationId}/designer`;
    this.$axios
      .get(url)
      .then((response) => {
        // debugger;
        this.designer = response.data.data;
        this.edit(this.designer[0].id); //display the first value
        this.isLoading = false; //stop the loading spinner
      })
      .catch((error) => {
        console.log(`api error:${error}`);
        this.isLoading = false; //stop the loading spinner
      });
    const consultancyUrl = "api/consultancy";
    this.$axios
      .get(consultancyUrl)
      .then((response) => {
        // debugger;
        this.consultancy = response.data.data;
        // this.newDesigner.clear();
      })
      .catch((error) => {
        console.log(`api error:${error}`);
      });

    // Get designer types api response
    this.getDesignerTypes();
  },
  methods: {
    // alertSuccess(message) {
    //   this.$swal("Added!", message, "success");
    // },
    // alertUpdate(message) {
    //   this.$swal("Updated!", message, "success");
    // },
    // alertDelete(message) {
    //   this.$swal("Deleted!", message, "success");
    // },
    alertError(message) {
      this.$swal.fire({
        icon: "error",
        title: message,
        text: "Please have a look on the form!",
      });
    },
    getOldData(oldData) {
      this.designer = oldData[0];
      this.designer.id = 0;
      console.log("Emit passed for old data");
    },
    getNibedanAuthorization() {
      this.isPostAuthorized = this.$store.getters.nibedanBibaranAuthData;
    },
    save(isNext) {
      //   debugger;
      this.isLoading = true;
      if (
        this.newDesigner.id === "null" ||
        this.newDesigner.id === 0 ||
        this.newDesigner.id === undefined
      ) {
        const saveUrl = `/api/registration/${this.registrationId}/designer`;
        this.$axios
          .post(saveUrl, this.newDesigner)
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.designer.push(response.data.data);
              this.isLoading = false;
              this.snackbar.show = true;
              this.successfulMessage = "Successfuly Added";
              // this.alertSuccess("Successfully Added !!");
              if (isNext) {
                this.nextStep(1);
              }
              this.$refs.form.reset();
            }
          })
          .catch((error) => {
            this.isLoading = false;
            // console.log(`api error:${error}`);
            let message = [];
            let errorResponse = error.response.data.errors;
            for (var i in errorResponse) {
              // errorText = errorText + `${i}: ${errorResponse[i]} \n`;
              message.push(errorResponse[i][0]+"<br>");
            }
            this.alertError(message);
          });
      } else {
        const eurl = `/api/registration/${this.$route.params.regId}/designer/${this.newDesigner.id}`;
        this.$axios
          .put(eurl, this.newDesigner, {})
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              // const currentIndex = this.designer.indexOf(this);
              // const tempDesigner = this.designer.find(item => item.id === id);
              const i = this.designer.map((item) => item.id).indexOf(this.newDesigner.id); // find index of your object
              this.designer[i].affiliated_consultancy_name =
                this.newDesigner.affiliated_consultancy_name;
              this.designer[i].registration_number = this.newDesigner.registration_number;
              this.designer[i].designer_council_number = this.newDesigner.designer_council_number;
              this.designer[i].designer_name = this.newDesigner.designer_name;
              this.designer[i].phone_no = this.newDesigner.phone_no;
              this.designer[i].email = this.newDesigner.email;
              // this.designer.$set(i, this.newDesigner);
              // this.designer.splice(i, 1, this.newDesigner);
              this.isLoading = false;
              this.snackbar.show = true;
              this.successfulMessage = "Successfuly Updated";
              if (isNext) {
                this.nextStep(1);
              }
              this.$refs.form.reset();
              this.newDesigner.id = 0;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            // console.log(error);
            let message = [];
            let errorResponse = error.response.data.errors;
            for (var i in errorResponse) {
              // errorText = errorText + `${i}: ${errorResponse[i]} \n`;
              message.push(errorResponse[i][0]+"<br>");
            }
            this.alertError(message);
          });
      }
    },
    consultancyDetail() {
      const url = `api/consultancy/${this.newDesigner.affiliated_consultancy_id}`;
      this.$axios.get(url).then((response) => {
        // debugger;
        this.newDesigner.affiliated_consultancy_name = response.data.data.name;
        this.newDesigner.registration_number = response.data.data.registration_number;
        console.log(response.data.data);
      });
    },
    getDesignerTypes() {
      const url = `api/designer-types`;
      this.$axios
        .get(url)
        .then((response) => {
          this.designerTypes = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(id) {
      //   debugger;
      //    const url = `api/registration/${this.$route.params.regId}/designer/${this.$route.params.id}`;
      const url = `api/registration/${this.$route.params.regId}/designer/${id}`;
      this.$axios.get(url).then((response) => {
        // debugger;
        this.newDesigner = response.data.data;
        console.log(response.data.data);
      });
    },
    deleterecord(id) {
      const deleteurl = `api/registration/${this.$route.params.regId}/designer/${id}`;
      this.$axios
        .delete(deleteurl)
        .then((response) => {
          // debugger;
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Data deleted successfully";
            // this.alertDelete("Successfully Deleted !!");
            const i = this.designer.map((item) => item.id).indexOf(id); // find index of your object
            this.designer.splice(i, 1);
          }
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 60px;
}

.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}

label span {
  color: red;
}

.error-message {
  padding: 10px !important;
}

.font-18 {
  font-size: 17px;
}

.v-input--selection-controls {
  margin-top: -5px !important;
}
</style>
