<template>
  <div>
    <!-- <h1 v-for="(l, index) in applicationIdList" :key="l">
      {{ l }} {{index}}
      
        @delete="deleteSingleApplicationForm($event, index)"
    </h1> -->
    <div v-for="(l, index) in applicationIdList" :key="index">
      <Application
        v-bind:next-step="nextStep"
        :isPostAuthorized="isPostAuthorized"
        v-bind:registration-id="registrationId"
        :application-index="index + 1"
        :application-id="l"
      />
      <!-- <v-row>
        <v-col class="mx-4 mb-4">
          <v-btn class="error" @click="deleteSingleApplicationForm(l, index)"
            >Delete</v-btn
          >
        </v-col>
      </v-row> -->
    </div>
    <div>
      <v-row class="ma-1">
        <v-col cols="4">
          <v-btn class="primary" v-if="isPostAuthorized" @click="addNewApplication">+Add Another</v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ApplicationSingle from "./ApplicationSingle.vue";
import Application from "./Application.vue"
export default {
  name: "AppicationRepeatablevtwo",
  props: {
    nextStep: {
      type: Function,
    },
    registrationId: {
      type: [String, Number],
    },
    id: {
      type: [String, Number],
    },
    isPostAuthorized: {
      type: Boolean,
    },
  },
  components: {
    // ApplicationSingle,
    Application,
  },
  data() {
    return {
      // applicationCount: 2,
      applicationIdList: [],
    };
  },
  computed: {
    applicationCount() {
      return this.applicationIdList.length;
    },
  },
  created() {
    this.getApplicationIdList();
  },
  mounted() {
    // this.getApplicationIdList();
  },
  methods: {
    getApplicationIdList() {
      const apiUrl = `api/registration/${this.$route.params.regId}/applicationIdList`;
      this.$axios
        .get(apiUrl)
        .then((response) => {
          this.applicationIdList = [];
          this.applicationIdList = response.data.data;
          if (!this.applicationIdList.length) {
            this.addNewApplication();
          }
        })
        .catch((error) => console.log(error));
    },
    deleterecord(id) {
      const deleteurl = `api/registration/${this.$route.params.regId}/application/${id}`;
      this.$axios
        .delete(deleteurl, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          // debugger;
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Data deleted successfully";
          }
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addNewApplication() {
      // this.applicationCount += 1;
      this.applicationIdList.push(0);
    },
    deleteSingleApplicationForm(id, index) {
      // if (index < this.applicationIdList.length) {
      this.applicationIdList.splice(index, 1);
      // }
      if (id != 0) {
        this.deleterecord(id);
      }
    },
  },
};
</script>

<style>
</style>