<template>
  <div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <v-card class="sober d-flex flex-column">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title> सुपरभाइजर </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="mx-4 my-4" v-model="valid">
        <v-row>
          <v-col cols="3" md="3">
            <label>Type<span> *</span></label>
          </v-col>
          <v-col cols="4" md="4">
            <v-radio-group v-model="newsupervisor.is_consulting_firm" :mandatory="true">
              <v-radio label="Consulting Firm" v-bind:value="1"
                ><label><span> *</span></label></v-radio
              >
              <v-radio label="Freelancer" v-bind:value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="4" md="4">
            <v-switch
              v-model="same_as_supervisor"
              label="Same as Designer"
              color="primary"
              hide-details
            ></v-switch>
          </v-col>
        </v-row>
        <br />
        <br />
        <v-row v-if="newsupervisor.is_consulting_firm" class="mt-2">
          <v-col cols="6" md="3"> आवद्ध कन्सल्टेन्सी </v-col>
          <v-col cols="6" md="4">
            <!-- <v-select v-model="newsupervisor.affiliated_consultancy" :items="supervisor" item-value="id" item-text="affiliated_consultancy_name" :key="id"   ></v-select> -->
            <v-select
              v-model="newsupervisor.affiliated_consultancy_id"
              :items="consultancy"
              item-value="id"
              item-text="name"
              @change="consultancyDetail()"
              :key="id"
              label="Select आवद्ध कन्सल्टेन्सी"
              dense
            ></v-select>
          </v-col>
          <!-- <v-col cols="6" md="3" v-if="regConstructionId !== 1">
            <v-switch
              v-model="same_as_old_data"
              label="Same as Old Data"
              color="primary"
              hide-details
            ></v-switch>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="6" md="3"> Consultancy's Municipality Registration Number </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model="newsupervisor.registration_number"
              label="Consultancy's Registration Number"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row hidden>
          <v-col cols="6" md="3"> आवद्ध कन्सल्टेन्सी </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model="newsupervisor.affiliated_consultancy_name"
              label="आवद्ध कन्सल्टेन्सी"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3"> Supervisor's Council No </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model="newsupervisor.supervisor_council_number"
              label="Supervisor's Council No"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3">
            <label> सुपरभाइजरको नाम<span> *</span></label>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model="newsupervisor.supervisor_name"
              :rules="requiredFormat('Supervisor Name', `${this.$regexList.nepName}`)"
              label="सुपरभाइजरको नाम"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3">
            <label> Supervisor's email</label>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model="newsupervisor.supervisor_email"
              label="Supervisor's email"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3">
            <label> Supervisor's Phone<span> *</span></label>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model="newsupervisor.supervisor_phone"
              :rules="requiredPhone('Supervisor Phone')"
              label="Supervisor's Phone"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-snackbar
          v-model="snackbar.show"
          :bottom="y === 'bottom'"
          :color="snackbar.color"
          :left="x === 'left'"
          :multi-line="mode === 'multi-line'"
          :right="x === 'right'"
          :timeout="3000"
          :top="y === 'top'"
          :vertical="mode === 'vertical'"
        >
          {{ successfulMessage }}
        </v-snackbar>
        <v-row>
          <v-col class="my-4">
            <v-btn
              primary
              v-if="isPostAuthorized"
              v-on:click="save(false)"
              class="primary"
              :disabled="!valid"
              >Save</v-btn
            >
            <v-btn
              primary
              v-on:click="save(true)"
              v-if="isPostAuthorized"
              class="primary ml-1"
              :disabled="!valid"
              >Save &amp; Next</v-btn
            >
            <v-btn class="ma-1" color="primary" @click="nextStep(1)"> Skip </v-btn>
          </v-col>
        </v-row>
        <br /><br />
      </v-form>
      <v-toolbar color="primary mt-2" dark flat>
        <v-toolbar-title> List </v-toolbar-title>
      </v-toolbar>
      <v-simple-table class="sober">
        <template v-slot:default>
          <thead>
            <tr class="registrationtable">
              <th class="text-left registrationtable table-title">आवद्ध कन्सल्टेन्सी</th>
              <th class="text-left registrationtable table-title">Supervisor</th>
              <th class="text-left registrationtable table-title">supervisor's Council No</th>
              <th class="text-left registrationtable table-title">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="registrationtable"
              v-for="supervisorList in supervisor"
              :key="supervisorList.id"
            >
              <td class="registrationtable">
                {{ supervisorList.affiliated_consultancy_name }}
              </td>
              <td class="registrationtable">
                {{ supervisorList.supervisor_name }}
              </td>
              <td>{{ supervisorList.supervisor_council_number }}</td>
              <td>
                <v-btn
                  small
                  color="primary"
                  v-if="isPostAuthorized"
                  v-on:click="edit(supervisorList.id)"
                  >Edit</v-btn
                >
                <!-- <v-btn
                  small
                  color="primary"
                  v-if="isPostAuthorized"
                  v-on:click="deleterecord(supervisorList.id)"
                  >Delete</v-btn
                > -->
                <custom-dialog
                  v-if="isPostAuthorized"
                  :click-dialog="deleterecord"
                  :regId="supervisorList.id"
                  click-type="delete"
                  >Delete</custom-dialog
                >
                <v-btn small color="primary" v-else v-on:click="edit(supervisorList.id)"
                  >View</v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <form-history :historyData="historyData"></form-history>
  </div>
</template>
<script>
import FormHistory from "../../history/FormHistory.vue";

export default {
  name: "Supervisor",
  props: {
    nextStep: {
      type: Function,
    },
    registrationId: {
      type: [String, Number],
    },
    id: {
      type: [String, Number],
    },
    application: {
      type: Object,
    },
    // isPostAuthorized: {
    //   type: Boolean,
    // },
    regConstructionId: {
      type: Number,
    },
  },

  components: {
    FormHistory,
  },
  data() {
    return {
      valid: false,
      same_as_old_data: false,
      historyData: {
        apiUrl: `api/registration/${this.registrationId}/supervisor/history`,
        formData: [
          {
            title: "आवद्ध कन्सल्टेन्सी",
            key: "affiliated_consultancy_name",
          },
          {
            title: "सुपरभाइजरको नाम",
            key: "supervisor_name",
          },
          {
            title: "Supervisor's Council No",
            key: "supervisor_council_number",
          },
          {
            title: "Supervisor Phone",
            key: "supervisor_phone",
          },
        ],
      },
      required(propertyType) {
        return (v) => !!v || `${propertyType} is required`;
      },
      requiredFormat(propertyType, regexexp) {
        var regex = new RegExp(regexexp, "g");
        return [
          (v) => !!v || `${propertyType} is required`,
          (v) => regex.test(v) || `${propertyType}को फर्मेट मिलेन ।`,
          // /^२[०-९]{3}-[०१]?[०-९]-[०-३]?[०-९]$/   ‍=> Date
        ];
      },
      requiredPhone(propertyType) {
        return [
          (v) => !!v || `${propertyType} is required`,
          // v => /^\w$/ || 'Please write in english format',
          (v) =>
            /^\+?([९७७,977])?[०-९,0-9]{9,15}$/.test(v) ||
            `${propertyType} is not valid phone number`,
        ];
      },
      date: "",
      supervisor: [],
      consultancy: [],
      userRoleId: localStorage.getItem("auth_user_role_id"),
      newsupervisor: {
        id: 0,
        reg_id: this.registrationId,
        is_consulting_firm: "",
        registration_number: "",
        affiliated_consultancy_id: "",
        affiliated_consultancy_name: "",
        supervisor_name: "",
        supervisor_council_number: "",
        supervisor_email: "",
        supervisor_phone: "",
      },
      isValid: true,
      isLoading: true,
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      same_as_supervisor: false,
      isPostAuthorized: false,
    };
  },
  created() {
    this.getNibedanAuthorization();
  },
  watch: {
    same_as_supervisor: function () {
      console.log("kimkartabyabimud");
      this.getDesignerData();
    },
  },
  mounted() {
    const url = `api/registration/${this.registrationId}/supervisor`;
    this.$axios
      .get(url)
      .then((response) => {
        // debugger;
        this.supervisor = response.data.data;
        this.edit(this.supervisor[0].id);
        this.isLoading = false; //stop the loading spinner
        // this.newsupervisor.clear();
      })
      .catch((error) => {
        console.log(`api error:${error}`);
        this.isLoading = false; //stop the loading spinner
      });
    const consultancyUrl = "api/consultancy";
    this.$axios
      .get(consultancyUrl)
      .then((response) => {
        // debugger;
        this.consultancy = response.data.data;
        console.log(this.consultancy);
      })
      .catch((error) => {
        console.log(`api error:${error}`);
      });
  },
  methods: {
    // alertSuccess(message) {
    //   this.$swal("Added!", message, "success");
    // },
    // alertUpdate(message) {
    //   this.$swal("Updated!", message, "success");
    // },
    // alertDelete(message) {
    //   this.$swal("Deleted!", message, "success");
    // },
    alertError(message) {
      this.$swal.fire({
        icon: "error",
        title: message,
        text: "Please have a look on the form!",
      });
    },
    getNibedanAuthorization() {
      this.isPostAuthorized = this.$store.getters.nibedanBibaranAuthData;
    },
    save(isNext) {
      //   debugger;
      this.isLoading = true;
      if (
        this.newsupervisor.id === "null" ||
        this.newsupervisor.id === 0 ||
        this.newsupervisor.id === undefined
      ) {
        const saveUrl = `/api/registration/${this.registrationId}/supervisor`;
        this.$axios
          .post(saveUrl, this.newsupervisor)
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.supervisor.push(response.data.data);
              this.isLoading = false;
              this.snackbar.show = true;
              this.successfulMessage = "Successfully Added";
              // this.alertSuccess("Successfully Added !!");
              if (isNext) {
                this.nextStep(1);
              }
              this.$refs.form.reset();
            }
          })
          .catch((error) => {
            this.isLoading = false;
            // console.log(`api error:${error}`);
            let message = [];
            let errorResponse = error.response.data.errors;
            for (var i in errorResponse) {
              // errorText = errorText + `${i}: ${errorResponse[i]} \n`;
              message.push(errorResponse[i][0]+"<br>");
            }
            this.alertError(message);
          });
      } else {
        const eurl = `/api/registration/${this.$route.params.regId}/supervisor/${this.newsupervisor.id}`;
        this.$axios
          .put(eurl, this.newsupervisor, {})
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              const i = this.supervisor.map((item) => item.id).indexOf(this.newsupervisor.id); // find index of your object
              this.supervisor[i].affiliated_consultancy_name =
                this.newsupervisor.affiliated_consultancy_name;
              this.supervisor[i].registration_number = this.newsupervisor.registration_number;
              this.supervisor[i].supervisor_council_number =
                this.newsupervisor.supervisor_council_number;
              this.supervisor[i].supervisor_name = this.newsupervisor.supervisor_name;
              this.supervisor[i].is_consulting_firm = this.newsupervisor.is_consulting_firm;
              this.supervisor[i].affiliated_consultancy_id =
                this.newsupervisor.affiliated_consultancy_id;
              this.isLoading = false;
              this.snackbar.show = true;
              this.successfulMessage = "Successfully Updated";
              // this.alertUpdate("Successfully Updated !!");
              if (isNext) {
                this.nextStep(1);
              }
              this.$refs.form.reset();
              this.newsupervisor.id = 0;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            // this.alertError(error);
            let message = [];
            let errorResponse = error.response.data.errors;
            for (var i in errorResponse) {
              // errorText = errorText + `${i}: ${errorResponse[i]} \n`;
              message.push(errorResponse[i][0]+"<br>");
            }
            this.alertError(message);
          });
      }
    },
    consultancyDetail() {
      const url = `api/consultancy/${this.newsupervisor.affiliated_consultancy_id}`;
      this.$axios.get(url).then((response) => {
        this.newsupervisor.affiliated_consultancy_name = response.data.data.name;
        this.newsupervisor.registration_number = response.data.data.registration_number;
        console.log(response.data.data);
      });
    },
    edit(id) {
      //   debugger;
      //    const url = `api/registration/${this.$route.params.regId}/supervisor/${this.$route.params.id}`;
      const url = `api/registration/${this.$route.params.regId}/supervisor/${id}`;
      this.$axios.get(url).then((response) => {
        // debugger;
        this.newsupervisor = response.data.data;
        console.log(response.data.data);
      });
    },
    deleterecord(id) {
      const deleteurl = `api/registration/${this.$route.params.regId}/supervisor/${id}`;
      this.$axios
        .delete(deleteurl)
        .then((response) => {
          // debugger;
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Successfully Deleted";
            // this.alertDelete("Successfully Deleted");
            const i = this.supervisor.map((item) => item.id).indexOf(id); // find index of your object
            this.supervisor.splice(i, 1);
          }
          console.log(response);
        })
        .catch((error) => {
          // console.log(error);
          let message = [];
            let errorResponse = error.response.data.errors;
            for (var i in errorResponse) {
              // errorText = errorText + `${i}: ${errorResponse[i]} \n`;
              message.push(errorResponse[i][0]+"<br>");
            }
            this.alertError(message);
        });
    },
    getDesignerData() {
      if (this.same_as_supervisor === true) {
        const sUrl = `api/registration/${this.registrationId}/designer`;
        this.$axios
          .get(sUrl)
          .then((response) => {
            // debugger;
            this.newsupervisor = response.data.data[0];
            this.newsupervisor.id = 0;
            this.newsupervisor.supervisor_name = response.data.data[0].designer_name;
            this.newsupervisor.supervisor_council_number =
              response.data.data[0].designer_council_number;
            this.newsupervisor.supervisor_email = response.data.data[0].email;
            this.newsupervisor.supervisor_phone = response.data.data[0].phone_no;
            // this.edit(this.supervisor[0].id); //display the first value
            this.isLoading = false; //stop the loading spinner
            // console.log("Chalyu");
          })
          .catch((error) => {
            console.log(`api error:${error}`);
            this.isLoading = false; //stop the loading spinner
          });
      }
    },
  },
};
</script>
<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 58px;
}

.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}
label span {
  color: red;
}
.error-message {
  padding: 10px !important;
}
.table-title {
  font-size: 17px;
}
</style>
