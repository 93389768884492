<template>
  <div>
    <b-button v-b-toggle.sidebar-right class="primary">दर्ता विवरण</b-button>
    <b-sidebar id="sidebar-right" title="दर्ता विवरण" right shadow>
      <div class="px-3 py-2">
        <v-simple-table>
          <tbody class="text-left">
            <tr>
              <td>
                घरधनीको नाम : <b>{{ results.houseowner_name_np }}</b>
              </td>
            </tr>
            <tr>
              <td>
                आर्थिक बर्ष: <b> {{ engNumToNep(results.fiscal_year) }} </b>
              </td>
            </tr>
            <tr>
              <td>
                सम्पर्क नं: <b>{{ engNumToNep(results.contact_no) }}</b>
              </td>
            </tr>
            <tr>
              <td>
                सडक न : <b>{{ engNumToNep(results.street_no) }}</b>
              </td>
            </tr>
            <tr>
              <td>
                जग्गा कित्ता न: <b>{{ engNumToNep(results.land_plot_no) }}</b>
              </td>
            </tr>
            <tr>
              <td>
                आन्तरिक संकेत न: <b>{{ engNumToNep(results.internal_code) }}</b>
              </td>
            </tr>
            <tr>
              <td>
                वडा नं: <b>{{ engNumToNep(results.ward_no?.toString()) }}</b>
              </td>
            </tr>
            <!-- <tr>
              <td>
                आर्थिक बर्ष: <b> {{ engNumToNep(results.fiscal_year) }} </b>
              </td>
              <td>
                सम्पर्क नं: <b>{{ engNumToNep(results.contact_no) }}</b>
              </td>
              <td>
                घरधनीको नाम : <b>{{ results.houseowner_name_np }}</b>
              </td>
              <td>
                सडक न : <b>{{ engNumToNep(results.street_no) }}</b>
              </td>
            </tr>
            <tr>
              <td>
                करदाता संकेत न:
                <b>{{ engNumToNep(results.houseowner_tax_no) }}</b>
              </td>
              <td>
                जग्गा कित्ता न: <b>{{ engNumToNep(results.land_plot_no) }}</b>
              </td>
              <td>
                आन्तरिक संकेत न: <b>{{ engNumToNep(results.internal_code) }}</b>
              </td>
              <td>
                वडा नं: <b>{{ engNumToNep(results.ward_no.toString()) }}</b>
              </td>
            </tr>
            <tr>
              <td>
                फारमको दर्ता मिति:
                <b>{{ data ? engNumToNep(data.registration_date_np) : "" }}</b>
              </td>
            </tr> -->
          </tbody>
        </v-simple-table>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { engNumConvert } from "../../mixins/numericToUnicode.js";
export default {
  name: "SidebarProfile",
  mixins: [engNumConvert],
  props: {
    registrationId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      results: [],
      data: {
        registration_date_np: "",
        registration_no: "",
      },
    };
  },
  mounted() {
    this.getFileData();
  },
  methods: {
    async getFileData() {
      //set results to empty before receiving new data. This will also start the loading spinner
      // this.results = [];
      let regDataTemp = this.$store.getters.registrationData;
      try {
        if (!Object.keys(regDataTemp).length || regDataTemp.id != this.registrationId) {
          await this.$store.dispatch("getRegistration", this.registrationId)
        }
        this.results = this.$store.getters.registrationData;
        this.data.registration_date_np = this.$store.getters.registrationData.registration_date_np;
      }
      catch (error) {
        console.log(error);
      }
    },
  },
};
</script>