<template>
  <div class="">
    <v-btn class="primary ma-1" :to="{ name: 'application_tab', params: { id: regId } }">Back</v-btn>
    <v-stepper v-model="el" class="vstepper">
      <v-layout justify-center>
        <v-flex xs2>
          <v-stepper-header class="stepperHeader">
            <template v-for="n in steps" class="stepperHeaderTemplate">
              <v-card :key="n.sn" class="sober elevation-12 pt-1 pb-1">
                <v-stepper-step :key="n.sn" :step="n.sn" editable complete class="vstepperStep">
                  {{ n.text }}
                </v-stepper-step>
              </v-card>
            </template>
          </v-stepper-header>
        </v-flex>
        <v-flex xs10>
          <v-card>
            <v-stepper-items>
              <v-stepper-content v-for="n in steps" :key="`${n.sn}-content`" :step="n.sn" vertical>
                <component class="sober" :application="application" :isPostAuthorized="(userRoleId == 2 || userRoleId == 8) && reg.desk_user_role_id == userRoleId && reg.phase_id != 6
                  " :is="getComponent(n.sn)" v-if="n.sn === el" v-bind:next-step="nextStep" v-bind:registration-id="regId" :id="id" />
              </v-stepper-content>
            </v-stepper-items>
          </v-card>
        </v-flex>
      </v-layout>
    </v-stepper>
  </div>
</template>
<script>

import Contractor from "./components/Contractor.vue";
import Electrician from "./components/Electrician.vue";
import Plumber from "./components/Plumber.vue";

const COMPONENT_MAP = {
  1: "Contractor",
  2: "Electrician",
  3: "Plumber"
};

export default {
  name: "TabbedElements",
  props: {
    id: {
      cast: Number
    },
    regId: {
      cast: Number
    },
    sn: {
      cast: Number
    }
  },
  data() {
    return {
      el: Number(this.sn),
      userRoleId: localStorage.getItem("auth_user_role_id"),
      vertical: true,
      // : this.regId,
      // id: this.id,
      reg: [],
      steps: [
        { text: "निर्माणकर्मी / ठेकेदार", sn: 1 },
        { text: "इलेक्ट्रीशियन", sn: 2 },
        { text: "प्लम्बर", sn: 3 },
      ]

      // el: 1,
      // steps: 10,
    };
  },
  components: {
    Contractor,
    Electrician,
    Plumber
    //  Mason,
    // Consultant,
  },

  watch: {
    steps(val) {
      if (this.el > val) {
        this.el = val;
      }
    }
  },

  methods: {
    getComponent(n) {
      return COMPONENT_MAP[n];
    },
    nextStep(n) {
      // debugger;
      if (n === this.steps.sn) {
        this.el = 1;
      } else {
        this.el += n;
      }
    }
  },
  mounted() {
    this.getRegistrationData();
  },
  methods: {
    async getRegistrationData() {
      let regDataTemp = this.$store.getters.registrationData;
      try {
        if (!Object.keys(regDataTemp).length || regDataTemp.id != this.$route.params.id) {
          await this.$store.dispatch("getRegistration", this.$route.params.id)
        }
        this.reg = this.$store.getters.registrationData;
      }
      catch (error) {
        console.log(error);
      }
    }
  },
};
</script>
<style scoped>
.vstepperStep {
  width: 230px;
  padding: 15px;
}

.vstepper {
  min-height: 100vh;
}

.stepperHeaderTemplate .stepperHeader {
  height: 10px;
  width: 300px;
}
</style>
