<template>
  <div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <v-card class="mb-12" color="sober" height="auto">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>
          इलेक्ट्रीशियन
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="mx-4" v-model="valid">
        <v-row>
          <v-col cols="2" class="vertical-center">
            Name
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="newelectrician.name"
              label=""
              class="mtn-1"
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="vertical-center">
            Certificate no
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="newelectrician.license_no"
              label=""
              class="mtn-1"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="vertical-center">
            Phone
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="newelectrician.phone_no"
              label=""
              class="mtn-1"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-snackbar
          v-model="snackbar.show"
          :bottom="y === 'bottom'"
          :color="snackbar.color"
          :left="x === 'left'"
          :multi-line="mode === 'multi-line'"
          :right="x === 'right'"
          :timeout="3000"
          :top="y === 'top'"
          :vertical="mode === 'vertical'"
        >
          {{ successfulMessage }}
        </v-snackbar>
        <v-btn primary v-if="isPostAuthorized" v-on:click="save" class="primary" :disabled="!valid"
          >Save</v-btn
        >
        <v-btn
          primary
          v-on:click="
            save();
            nextStep(1);
          "
          v-if="isPostAuthorized"
          class="primary ml-1"
          :disabled="!valid"
          >Save &amp; Next</v-btn
        >
        <v-btn class="ma-1" color="primary" @click="nextStep(1)">
          Next
        </v-btn>
      </v-form>
      <v-toolbar color="primary mt-2" dark flat>
        <v-toolbar-title>
          List
        </v-toolbar-title>
      </v-toolbar>
      <v-simple-table class="sober">
        <template v-slot:default>
          <thead>
            <tr class="registrationtable">
              <th class="text-left registrationtable table-title">
                Name
              </th>
              <th class="text-left registrationtable table-title">
                Phone
              </th>
              <th class="text-left registrationtable table-title">Certificate No</th>
              <th class="text-left registrationtable table-title">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="registrationtable"
              v-for="electricianList in electrician"
              :key="electricianList.id"
            >
              <td class="registrationtable">
                {{ electricianList.name }}
              </td>
              <td>{{ electricianList.phone_no }}</td>
              <td class="registrationtable">{{ electricianList.license_no }}</td>
              <td>
                <v-btn
                  small
                  color="primary"
                  v-if="isPostAuthorized"
                  v-on:click="edit(electricianList.id)"
                  >Edit</v-btn
                >
                <!-- <v-btn
                  small
                  color="primary"
                  v-if="isPostAuthorized"
                  v-on:click="deleterecord(electricianList.id)"
                  >Delete</v-btn
                > -->
                <custom-dialog
                  v-if="isPostAuthorized"
                  :click-dialog="deleterecord"
                  :regId="electricianList.id"
                  click-type="delete"
                >
                  Delete
                </custom-dialog>
                <v-btn small color="primary" v-else v-on:click="edit(landownerList.id)">View</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "electrician",
  props: {
    nextStep: {
      type: Function
    },
    registrationId: {
      type: [String, Number]
    },
    id: {
      type: [String, Number]
    },
    application: {
      type: Object
    },
    isPostAuthorized: {
      type: Boolean
    }
  },

  data() {
    return {
      valid: false,
      isLoading: true,
      date: "",
      userRoleId: localStorage.getItem("auth_user_role_id"),
      electrician: [],
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success"
      },
      newelectrician: {
        id: 0,
        reg_id: this.registrationId,
        name: "",
        license_no: "",
        phone_no: ""
      }
    };
  },
  mounted() {
    // console.log(localStorage.getItem("token"));

    const url = `api/registration/${this.registrationId}/electrician`;
    this.$axios
      .get(url)
      .then(response => {
        // debugger;
        this.electrician = response.data.data;
        this.edit(this.electrician[0].id);
        this.isLoading = false; //stop the loading spinner
        // this.newelectrician.clear();
      })
      .catch(error => {
        console.log(`api error:${error}`);
        this.isLoading = false; //stop the loading spinner
      });
  },
  methods: {
    clearAllData() {
      this.newelectrician.id = 0;
      this.newelectrician.reg_id = this.registrationId;
      this.newelectrician.name = "";
      this.newelectrician.license_no = "";
      this.newelectrician.phone_no = "";
    },
    save() {
      //   debugger;
      this.isLoading = true;
      if (
        this.newelectrician.id === "null" ||
        this.newelectrician.id === 0 ||
        this.newelectrician.id === undefined
      ) {
        const saveUrl = `/api/registration/${this.registrationId}/electrician`;
        this.$axios
          .post(saveUrl, this.newelectrician)
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              this.electrician.push(response.data.data);
              this.isLoading = false;
              this.snackbar.show = true;
              this.successfulMessage = "Record added Successfuly.";
              this.$refs.form.reset();
            }
          })
          .catch(error => {
            // alert(2);
            this.isLoading = false;
            console.log(`api error:${error}`);
          });
      } else {
        const eurl = `/api/registration/${this.$route.params.regId}/electrician/${this.newelectrician.id}`;
        this.$axios
          .put(eurl, this.newelectrician)
          .then(response => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              const i = this.electrician.map(item => item.id).indexOf(this.newelectrician.id); // find index of your object
              this.electrician[i].name = this.newelectrician.name;
              this.electrician[i].license_no = this.newelectrician.license_no;
              this.electrician[i].phone_no = this.newelectrician.phone_no;
              this.isLoading = false;
              this.snackbar.show = true;
              this.successfulMessage = "Data updated successfully";
              this.$refs.form.reset();
              this.newelectrician.id = 0;
            }
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error);
          });
      }
    },
    edit(id) {
      //   debugger;
      //    const url = `api/registration/${this.$route.params.regId}/electrician/${this.$route.params.id}`;
      const url = `api/registration/${this.$route.params.regId}/electrician/${id}`;
      this.$axios.get(url).then(response => {
        // debugger;
        this.newelectrician = response.data.data;
        console.log(response.data.data);
      });
    },
    deleterecord(id) {
      const deleteurl = `api/registration/${this.$route.params.regId}/electrician/${id}`;
      this.$axios
        .delete(deleteurl)
        .then(response => {
          // debugger;
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Data deleted successfully";
            const i = this.electrician.map(item => item.id).indexOf(id); // find index of your object
            this.electrician.splice(i, 1);
          }
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
/* #styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20px;
} */

.row,
.col {
  height: 58px;
}

.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  margin-top: -2rem;
}
.table-title {
  font-size: 17px;
}

.vertical-center {
  margin-top: auto;
  margin-bottom: auto;
}

.mtn-1{
    margin-top: -1rem;
}

</style>
