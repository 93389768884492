<template>
  <div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <v-card class="sober d-flex flex-column">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title> जग्गाको विवरण (ठेगाना) </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="mx-4 my-4" v-model="valid">
        <v-row>
          <v-col cols="6" md="3">
            <label> हालको वार्ड नं.<span> *</span></label>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field v-model="newlandinfoAddress.ward_no" :rules="requiredFormat('Ward No.', '^[0-9०-९]+$')" label="वार्ड न"></v-text-field>
          </v-col>
          <!-- <v-col cols="4" v-if="regConstructionId !== 1">
            <v-switch
              v-model="same_as_old_data"
              label="Same as Old Data"
              color="primary"
              hide-details
            ></v-switch>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="6" md="3"> साविक कित्ता नं./ वार्ड नं </v-col>
          <v-col cols="6" md="4">
            <v-text-field v-model="newlandinfoAddress.previous_address" class="custom-text-field" label="साविक कित्ता नं."></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3">
            <label> टोल<span> *</span></label>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field v-model="newlandinfoAddress.tole_np" :rules="[required('Tole (NP)')]" class="custom-text-field" label="टोल"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3">
            <label> Tole<span> *</span></label>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field v-model="newlandinfoAddress.tole" :rules="[required('Tole')]" label="Tole" class="custom-text-field"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3"> सडकको कोड न </v-col>
          <v-col cols="6" md="4">
            <v-text-field v-model="newlandinfoAddress.street_code_no" class="custom-text-field" label="सडकको कोड न"></v-text-field>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="2"> कित्ताकाट भएको छ कि छैन ? </v-col>
          <v-col cols="4">
            <v-switch v-model="isKittaKaat" :label="isKittaKaat == true ? 'छ' : 'छैन'"></v-switch>
          </v-col>
        </v-row> -->
        <v-snackbar v-model="snackbar.show" :bottom="y === 'bottom'" :color="snackbar.color" :left="x === 'left'" :multi-line="mode === 'multi-line'" :right="x === 'right'"
          :timeout="3000" :top="y === 'top'" :vertical="mode === 'vertical'">
          {{ successfulMessage }}
        </v-snackbar>
        <v-row>
          <v-col class="my-4">
            <v-btn primary v-if="isPostAuthorized" v-on:click="save(false)" class="primary" :disabled="!valid">Save</v-btn>
            <v-btn primary v-on:click="save(true)" v-if="isPostAuthorized" class="primary ml-1" :disabled="!valid">Save &amp; Next</v-btn>
            <v-btn class="ma-1" color="primary" @click="nextStep(1)"> Skip </v-btn>
          </v-col>
        </v-row>
        <br />
        <br />
      </v-form>
      <v-toolbar color="primary mt-2" dark flat>
        <v-toolbar-title> List </v-toolbar-title>
      </v-toolbar>
      <v-simple-table class="sober">
        <template v-slot:default>
          <thead>
            <tr class="registrationtable">
              <th class="text-left registrationtable table-title">वार्ड न</th>
              <th class="text-left registrationtable table-title">टोल</th>
              <th class="text-left registrationtable table-title">सडकको कोड न</th>
              <th class="text-left registrationtable table-title">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr class="registrationtable" v-for="landinfoAddressList in landinfoAddress" :key="landinfoAddressList.id">
              <td class="registrationtable">
                {{ landinfoAddressList.ward_no }}
              </td>
              <td class="registrationtable">
                {{ landinfoAddressList.tole }}
              </td>
              <td>{{ landinfoAddressList.street_code_no }}</td>
              <td>
                <v-btn small color="primary" v-if="isPostAuthorized" v-on:click="edit(landinfoAddressList.id)">Edit</v-btn>
                <!-- <v-btn
                  small
                  color="primary"
                  v-if="isPostAuthorized"
                  v-on:click="deleterecord(landinfoAddressList.id)"
                  >Delete</v-btn
                > -->
                <custom-dialog v-if="isPostAuthorized" :click-dialog="deleterecord" :regId="landinfoAddressList.id" click-type="delete">Delete</custom-dialog>
                <v-btn small color="primary" v-else v-on:click="edit(landinfoAddressList.id)">View</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <form-history :historyData="historyData"></form-history>
    <!-- <v-btn
      color="primary"
      @click="nextStep(5)"
    >
      Continue
    </v-btn>

    <v-btn text>Cancel</v-btn> -->
  </div>
</template>
<script>
import FormHistory from "../../history/FormHistory.vue";

export default {
  name: "LandInfoAddress",
  props: {
    nextStep: {
      type: Function,
    },
    registrationId: {
      type: [String, Number],
    },
    id: {
      type: [String, Number],
    },
    application: {
      type: Object,
    },
    // isPostAuthorized: {
    //   type: Boolean,
    // },
    regConstructionId: {
      type: Number,
    },
  },

  components: {
    FormHistory,
  },

  data() {
    return {
      valid: false,
      same_as_old_data: false,
      historyData: {
        apiUrl: `api/registration/${this.registrationId}/land-info-address/history`,
        formData: [
          {
            title: "हालको वार्ड न",
            key: "previous_ward_no",
          },
          {
            title: "साविक कित्ता नं.",
            key: "previous_address",
          },
          {
            title: "टोल",
            key: "tole_np",
          },
          {
            title: "Tole",
            key: "tole",
          },
        ],
      },
      required(propertyType) {
        return (v) => !!v || `${propertyType} is required`;
      },
      requiredNumber(propertyType) {
        return (v) => (v && v > 0) || `${propertyType} is required`;
      },
      requiredFormat(propertyType, regexexp) {
        var regex = new RegExp(regexexp, "g");
        return [
          (v) => !!v || `${propertyType} is required`,
          (v) => regex.test(v) || `${propertyType}को फर्मेट मिलेन ।`,
          // /^२[०-९]{3}-[०१]?[०-९]-[०-३]?[०-९]$/   ‍=> Date
        ];
      },
      //   application: [],
      userRoleId: localStorage.getItem("auth_user_role_id"),
      date: "",
      landInfoAddress: [],
      newlandinfoAddress: {
        id: 0,
        reg_id: this.registrationId,
        ward_no: "",
        previous_address: "",
        street_code_no: "",
        tole: "",
        tole_np: "",
      },
      isValid: true,
      isLoading: true,
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      isPostAuthorized: false,
      newRegData: {},
      isKittaKaat: false,
    };
  },
  created() {
    this.getNibedanAuthorization();
  },
  mounted() {
    const url = `api/registration/${this.registrationId}/land-info-address`;
    this.$axios
      .get(url)
      .then((response) => {
        // debugger;
        this.landinfoAddress = response.data.data;
        this.edit(this.landinfoAddress[0].id);
        this.isLoading = false; //stop the loading spinner
        // this.newlandinfoAddress.clear();
      })
      .catch((error) => {
        console.log(`api error:${error}`);
        this.isLoading = false; //stop the loading spinner
      });
    this.getNewRegData();
  },
  methods: {
    // alertSuccess(message) {
    //   this.$swal("Added!", message, "success");
    // },
    // alertUpdate(message) {
    //   this.$swal("Updated!", message, "success");
    // },
    // alertDelete(message) {
    //   this.$swal("Deleted!", message, "success");
    // },
    alertError(message) {
      this.$swal.fire({
        icon: "error",
        title: message,
        text: "Please have a look on the form!",
      });
    },
    getOldData(oldData) {
      this.landinfoAddress = oldData[0];
      this.landinfoAddress.id = 0;
      console.log("Emit passed for old data");
    },
    getNibedanAuthorization() {
      this.isPostAuthorized = this.$store.getters.nibedanBibaranAuthData;
    },
    save(isNext) {
      //   debugger;
      this.isLoading = true;
      if (
        this.newlandinfoAddress.id === "null" ||
        this.newlandinfoAddress.id === 0 ||
        this.newlandinfoAddress.id === undefined
      ) {
        const saveUrl = `/api/registration/${this.registrationId}/land-info-address`;
        this.$axios
          .post(saveUrl, this.newlandinfoAddress)
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.landinfoAddress.push(response.data.data);
              this.isLoading = false;
              this.snackbar.show = true;
              this.successfulMessage = "Successfully Added";
              // this.alertSuccess("Successfully Added !!");
              if (isNext) {
                this.nextStep(1);
              }
              this.$refs.form.reset();
            }
          })
          .catch((error) => {
            this.isLoading = true;
            // console.log(`api error:${error}`);
            let message = [];
            let errorResponse = error.response.data.errors;
            for (var i in errorResponse) {
              // errorText = errorText + `${i}: ${errorResponse[i]} \n`;
              message.push(errorResponse[i][0] + "<br>");
            }
            this.alertError(message);
          });
      } else {
        const eurl = `/api/registration/${this.$route.params.regId}/land-info-address/${this.newlandinfoAddress.id}`;
        this.$axios
          .put(
            eurl,
            this.newlandinfoAddress,

            {}
          )
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              const i = this.landinfoAddress
                .map((item) => item.id)
                .indexOf(this.newlandinfoAddress.id); // find index of your object
              this.landinfoAddress[i].ward_no = this.newlandinfoAddress.ward_no;
              this.landinfoAddress[i].street_code_no = this.newlandinfoAddress.street_code_no;
              this.landinfoAddress[i].previous_address = this.newlandinfoAddress.previous_address;
              this.landinfoAddress[i].tole = this.newlandinfoAddress.tole;
              this.landinfoAddress[i].tole_np = this.newlandinfoAddress.tole_np;
              this.isLoading = false;
              this.snackbar.show = true;
              this.successfulMessage = "Successfully Updated";
              // this.alertUpdate("Successfully Updated !!");
              if (isNext) {
                this.nextStep(1);
              }
              this.$refs.form.reset();
              this.newlandinfoAddress.id = 0;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            // console.log(error);
            let message = [];
            let errorResponse = error.response.data.errors;
            for (var i in errorResponse) {
              // errorText = errorText + `${i}: ${errorResponse[i]} \n`;
              message.push(errorResponse[i][0] + "<br>");
            }
            this.alertError(message);
          });
      }
    },
    edit(id) {
      //   debugger;
      //    const url = `api/registration/${this.$route.params.regId}/designer/${this.$route.params.id}`;
      const url = `api/registration/${this.$route.params.regId}/land-info-address/${id}`;
      this.$axios.get(url).then((response) => {
        // debugger;
        this.newlandinfoAddress = response.data.data;
        console.log(response.data.data);
      });
    },
    deleterecord(id) {
      const deleteurl = `api/registration/${this.$route.params.regId}/land-info-address/${id}`;
      this.$axios
        .delete(deleteurl)
        .then((response) => {
          // debugger;
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Data deleted successfully";
            // this.alertDelete("Successfully Deleted !!");
            const i = this.landinfoAddress.map((item) => item.id).indexOf(id); // find index of your object
            this.landinfoAddress.splice(i, 1);
          }
          console.log(response);
        })
        .catch((error) => {
          // console.log(error);
          let message = [];
          let errorResponse = error.response.data.errors;
          for (var i in errorResponse) {
            // errorText = errorText + `${i}: ${errorResponse[i]} \n`;
            message.push(errorResponse[i][0] + "<br>");
          }
          this.alertError(message);
        });
    },
    async getNewRegData() {
      let regDataTemp = this.$store.getters.registrationData;
      try {
        if (!Object.keys(regDataTemp).length || regDataTemp.id != this.$route.params.id) {
          await this.$store.dispatch("getRegistration", this.$route.params.id)
        }
        this.newRegData = this.$store.getters.registrationData;
        if (this.newlandinfoAddress.ward_no == "") {
          this.newlandinfoAddress.ward_no = this.newRegData.ward_no;
        }
      }
      catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 58px;
}

.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}

label span {
  color: red;
}

.table-title {
  font-size: 17px;
}
</style>
