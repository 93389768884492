<template>
  <div class="my-4">
    <template v-if="apiData.length > 0">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title> History </v-toolbar-title>
      </v-toolbar>
      <div class="pa-2" v-for="singleData in apiData" :key="singleData">
        <v-row v-for="i in historyData.formData" :key="i">
          <v-col cols="4">{{ i.title }}</v-col>
          <v-col cols="auto">{{ singleData[i.key] }}</v-col>
        </v-row>
        <v-divider></v-divider>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    apiUrl: {
      type: String,
    },
    historyData: {
      type: [Object, Array],
    },
    sameAsOldData: {
      type: Boolean,
    },
  },
  data() {
    return {
      apiData: [],
    };
  },
  watch: {
    sameAsOldData() {
      if (this.sameAsOldData == true) {
        this.getOldData();
      }
    },
  },
  mounted() {
    this.getApiData();
  },
  methods: {
    getApiData() {
      const historyUrl = this.historyData.apiUrl;
      if (historyUrl != null) {
        this.$axios
          .get(historyUrl)
          .then((response) => {
            this.apiData = response.data.data;
            this.getOldData();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getOldData() {
      this.$emit("getOldData", this.apiData);
    },
  },
};
</script>