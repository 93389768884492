<template>
  <div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <v-card class="sober d-flex flex-column">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title> निवेदकको विवरण </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="mx-4 my-4" v-model="valid">
        <v-row>
          <v-col cols="4" md="2">
            <label> निवेदक <span>*</span></label>
          </v-col>
          <v-col cols="2" md="3">
            <v-select
              v-model="newapplicant.applicant_type_id"
              :rules="[required('Applicant Type')]"
              class="custom-text-field"
              :items="applicantType"
              item-value="id"
              item-text="applicant_type_name"
              :key="id"
            ></v-select>
          </v-col>
          <!-- <v-col cols="4" v-if="regConstructionId != 1">
            <v-switch
              v-model="same_as_old_data"
              :label="regConstructionId"
              color="primary"
              hide-details
            ></v-switch>
          </v-col> -->
          <!-- <v-col cols="4">
            <img :src="uploadedDocument" width="100px" />
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="4" md="2"> घरधनी </v-col>
          <v-col cols="2" md="3">
            <v-select
              v-model="houseownerId"
              @change="houseownerDetail()"
              :items="houseownerinfo"
              item-value="id"
              class="custom-text-field v-text-field__details"
              item-text="name_np"
              :key="id"
            ></v-select>
          </v-col>
          <!-- </div> -->
        </v-row>
        <v-row>
          <v-col cols="4" md="2">
            <label> घरधनीसँगको सम्बन्ध <span>*</span></label>
          </v-col>
          <v-col cols="2" md="3">
            <v-select
              class="custom-text-field v-text-field__details"
              v-model="newapplicant.ho_applicant_relation"
              :rules="[required('Applicant Relation')]"
              :items="applicantRelations"
              item-value="value"
              item-text="value"
              :key="id"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" md="2">
            <label> श्रीमान / श्रीमती <span>*</span></label>
          </v-col>
          <v-col cols="2" md="3">
            <!-- <v-text-field v-model="newapplicant.salutation" label=""       class="custom-text-field v-text-field__details"></v-text-field> -->
            <v-select
              v-model="newapplicant.salutation"
              :rules="[required('Salutation')]"
              :items="salutation"
              item-value="value"
              class="custom-text-field"
              item-text="value"
              :key="id"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" md="2"> वारिशको नाम (EN) </v-col>
          <v-col cols="2" md="3">
            <v-text-field
              v-model="newapplicant.nominee_name"
              :rules="regFormat('वारिशको नाम', `${this.$regexList.engName}`)"
              label="Nominee Name"
              class="custom-text-field v-text-field__details"
            ></v-text-field>
          </v-col>
          <v-col cols="4" md="2">वारिशको नाम (NP) </v-col>
          <v-col cols="2" md="3">
            <v-text-field
              v-model="newapplicant.nominee_name_np"
              :rules="regFormat('वारिशको नाम', `${this.$regexList.nepName}`)"
              label="वारिशको नाम"
              class="custom-text-field v-text-field__details"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" md="2"> निवेदकको नाम (EN) </v-col>
          <v-col cols="2" md="3">
            <v-text-field
              v-model="newapplicant.applicant_name"
              :rules="regFormat('वारिशको नाम', `${this.$regexList.engName}`)"
              label="Applicant Name"
              class="custom-text-field v-text-field__details"
            ></v-text-field>
          </v-col>
          <v-col cols="4" md="2">निवेदकको नाम (NP) </v-col>
          <v-col cols="2" md="3">
            <v-text-field
              v-model="newapplicant.applicant_name_np"
              :rules="regFormat('वारिशको नाम', `${this.$regexList.nepName}`)"
              label="निवेदकको नाम"
              class="custom-text-field v-text-field__details"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" md="2"> जग्गाधनी </v-col>
          <v-col cols="2" md="3">
            <!-- <v-text-field v-model="newapplicant.citizenship_district_id" label=""      ></v-text-field> -->
            <v-select
              v-model="landownerId"
              :items="landownerinfo"
              @change="landownerDetail()"
              item-value="id"
              item-text="landowner_name"
              :key="id"
              class="custom-text-field v-text-field__details"
            ></v-select>
          </v-col>
          <v-col cols="4" md="2"> नागरिकता नम्बर </v-col>
          <v-col cols="2" md="3">
            <v-text-field
              v-model="newapplicant.citizenship_no"
              label=""
              class="custom-text-field v-text-field__details"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" md="2"> नागरिकता लिएको जिल्ला </v-col>
          <v-col cols="2" md="3">
            <!-- <v-text-field v-model="newapplicant.citizenship_issue_district_id "></v-text-field> -->
            <v-autocomplete
              v-model="newapplicant.citizenship_issue_district_id"
              :items="districts"
              item-value="id"
              item-text="district_name"
              :key="id"
            ></v-autocomplete>
          </v-col>
          <v-col cols="3" md="2"> फोन नं </v-col>
          <v-col cols="3" md="3">
            <v-text-field v-model="newapplicant.phone_no" label="" required></v-text-field>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col md="2">
            फोटो
          </v-col>
          <v-col cols="12" md="4">
            <v-file-input v-model="newapplicant.photo" label="Select Photo.."></v-file-input>
          </v-col>
        </v-row> -->
        <br /><br />
        <v-row>
          <v-col class="my-4">
            <v-btn
              primary
              v-if="isPostAuthorized"
              v-on:click="save(false)"
              class="primary"
              :disabled="!valid"
              >Save</v-btn
            >
            <v-btn
              class="primary mx-2"
              :to="{ name: 'application_tab', params: { id: this.registrationId } }"
              >Back to Main</v-btn
            >
          </v-col>
        </v-row>
        <br />
      </v-form>
      <v-snackbar
        v-model="snackbar.show"
        :bottom="y === 'bottom'"
        :color="snackbar.color"
        :left="x === 'left'"
        :multi-line="mode === 'multi-line'"
        :right="x === 'right'"
        :timeout="3000"
        :top="y === 'top'"
        :vertical="mode === 'vertical'"
      >
        {{ successfulMessage }}
      </v-snackbar>
      <v-toolbar color="primary mt-2" dark flat>
        <v-toolbar-title> List </v-toolbar-title>
      </v-toolbar>
      <v-simple-table class="sober registrationtable">
        <template v-slot:default>
          <thead>
            <tr class="registrationtable">
              <th class="text-left registrationtable table-title">निवेदकको नाम</th>
              <!-- <th class="text-left registrationtable">
                                   Ward no
                                </th> -->
              <th class="text-left registrationtable table-title">नागरिकता नम्बर</th>
              <th class="text-left registrationtable table-title">फोन नं</th>
              <th class="text-left registrationtable table-title">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="registrationtable"
              v-for="applicantList in applicant"
              :key="applicantList.id"
            >
              <td class="registrationtable">
                {{ applicantList.applicant_name_np }}
              </td>
              <!-- <td class="registrationtable">
                                    {{ applicantList.ward_no }}
                                </td> -->
              <td>{{ applicantList.citizenship_no }}</td>
              <td>{{ applicantList.phone_no }}</td>
              <td>
                <v-btn
                  small
                  class="ma-1"
                  color="primary"
                  v-if="isPostAuthorized"
                  v-on:click="edit(applicantList.id)"
                  >Edit</v-btn
                >
                <!-- <v-btn
                  small
                  color="primary"
                  v-if="isPostAuthorized"
                  v-on:click="deleterecord(applicantList.id)"
                  >Delete</v-btn
                > -->
                <custom-dialog
                  v-if="isPostAuthorized"
                  :click-dialog="deleterecord"
                  :regId="applicantList.id"
                  click-type="delete"
                >
                  Delete
                </custom-dialog>
                <v-btn small color="primary" v-else v-on:click="edit(applicantList.id)">View</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <form-history :historyData="historyData"></form-history>
  </div>
</template>
<script>
import FormHistory from "../../history/FormHistory.vue";
export default {
  name: "Applicant",
  props: {
    nextStep: {
      type: Function,
    },
    registrationId: {
      type: [String, Number],
    },
    id: {
      type: [String, Number],
    },
    application: {
      type: Object,
    },
    // isPostAuthorized: {
    //   type: Boolean,
    // },
    regConstructionId: {
      type: Number,
    },
  },

  components: {
    // FormHistory,
  },

  data() {
    return {
      basePath: process.env.VUE_APP_BASE_URL,
      valid: false,
      isLoading: true,
      same_as_old_data: false,
      historyData: {
        apiUrl: `api/registration/${this.registrationId}/applicant/history`,
        formData: [
          {
            title: "निवेदकको नाम ",
            key: "applicant_name_np",
          },
          {
            title: "निवेदकको नाम (EN)",
            key: "applicant_name",
          },
          {
            title: "नागरिकता नं",
            key: "citizenship_no",
          },
        ],
      },
      required(propertyType) {
        return (v) => !!v || `${propertyType} is required`;
      },
      // format(propertyType, regexexp) {
      //   var regex = new RegExp(regexexp, "g");
      //   return [
      //     v => regex.test(v) || `${propertyType}को फर्मेट मिलेन ।`
      //     // /^२[०-९]{3}-[०१]?[०-९]-[०-३]?[०-९]$/   ‍=> Date
      //   ];
      // },
      regFormat(propertyType, regexexp) {
        var regex = new RegExp(regexexp, "g");
        return [
          (v) => !v || regex.test(v) || `${propertyType}को फर्मेट मिलेन ।`,
          // /^२[०-९]{3}-[०१]?[०-९]-[०-३]?[०-९]$/   ‍=> Date
        ];
      },
      houseownerinfo: [],
      districts: [],
      landownerinfo: [],
      salutation: [
        { id: 1, value: "श्रीमान" },
        { id: 2, value: "श्रीमती" },
      ],
      date: "",
      applicantType: [],
      options: [
        { id: 1, value: "जग्गाधनी" },
        { id: 2, value: "वारिश" },

        { id: 3, value: "घरधनी" },
      ],
      applicantRelations: [
        { id: 1, value: "स्वयं" },
        { id: 2, value: "छोरा/छोरी" },
        { id: 3, value: "आमा/बुवा" },

        { id: 4, value: "नातेदार" },
        { id: 5, value: "काममात्र सम्बन्ध" },
      ],
      houseownerId: 0,
      landownerId: 0,
      applicant: [],
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      userRoleId: localStorage.getItem("auth_user_role_id"),
      newapplicant: {
        id: 0,
        reg_id: this.registrationId,
        applicant_type_id: "",
        salutation: "",
        ho_applicant_relation: "",
        nominee_name: " ",
        nominee_name_np: " ",
        applicant_name: "",
        applicant_name_np: "",
        citizenship_no: "",
        citizenship_issue_district_id: "",
        phone_no: "",
        photo: "",
        ward_no: "",
      },
      formData: new FormData(),
      uploadedDocument: "",
      isPostAuthorized: false,
    };
  },
  created() {
    this.getNibedanAuthorization();
  },
  mounted() {
    const url = `api/registration/${this.registrationId}/applicant`;
    this.$axios
      .get(url)
      .then((response) => {
        // debugger;
        this.applicant = response.data.data;
        this.edit(this.applicant[0].id);
        this.uploadedDocument = this.basePath + applicant[0].photo;
        this.isLoading = false; //stop the loading spinner
        // this.newapplicant.clear();
      })
      .catch((error) => {
        console.log(`api error:${error}`);
        this.isLoading = false; //stop the loading spinner
      });
    const houseownerinfoUrl = `api/registration/${this.registrationId}/houseowner-detail`;
    this.$axios
      .get(houseownerinfoUrl)
      .then((response) => {
        // debugger;
        this.houseownerinfo = response.data.data;
        console.log(this.houseownerinfo);
        //   this.newhouseownerinfo.clear();
      })
      .catch((error) => {
        console.log(`api error:${error}`);
      });
    const landownerinfoUrl = `api/registration/${this.registrationId}/landowner-info`;
    this.$axios
      .get(landownerinfoUrl)
      .then((response) => {
        // debugger;
        this.landownerinfo = response.data.data;
        console.log(this.landownerinfo);
        //   this.newhouseownerinfo.clear();
      })
      .catch((error) => {
        console.log(`api error:${error}`);
      });
    const districtUrl = "api/districts";
    this.$axios
      .get(districtUrl)
      .then((response) => {
        // debugger;
        this.districts = response.data.data;
      })
      .catch((error) => {
        console.log(`api error:${error}`);
      });
    const applicantTypeUrl = "api/applicant-type";
    this.$axios
      .get(applicantTypeUrl)
      .then((response) => {
        // debugger;
        this.applicantType = response.data.data;
      })
      .catch((error) => {
        console.log(`api error:${error}`);
      });
  },
  methods: {
    // alertSuccess(message) {
    //   this.$swal("Added!", message, "success");
    // },
    // alertUpdate(message) {
    //   this.$swal("Updated!", message, "success");
    // },
    // alertDelete(message) {
    //   this.$swal("Deleted!", message, "success");
    // },
    alertError(message) {
      this.$swal.fire({
        icon: "error",
        title: message,
        text: "Please have a look on the form!",
      });
    },
    getNibedanAuthorization() {
      this.isPostAuthorized = this.$store.getters.nibedanBibaranAuthData;
    },
    getOldData(oldData) {
      this.newapplicant = oldData[0];
      this.newapplicant.id = 0;
      console.log("Emit passed for old data");
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    houseownerDetail() {
      // The user details are accessed from houseowner. Reset landowner
      // this.landownerId = 0;
      console.log(this.houseownerId);
      const url = `api/registration/${this.registrationId}/houseowner-detail/${this.houseownerId}`;
      this.$axios.get(url).then((response) => {
        // debugger;
        this.newapplicant.salutation = response.data.data.salutation;
        this.newapplicant.citizenship_no = response.data.data.citizenship_no;
        this.newapplicant.phone_no = response.data.data.phone_no;
        this.newapplicant.applicant_name_np = response.data.data.name_np;
        this.newapplicant.applicant_name = response.data.data.name;
        this.newapplicant.ward_no = response.data.data.ward_no;
        this.newapplicant.citizenship_issue_district_id =
          response.data.data.citizenship_district_id;
      });
    },
    landownerDetail() {
      // The user details are accessed from landowner. Reset houseowner
      // this.houseownerId = 0;
      console.log(this.houseownerId);
      const url = `api/registration/${this.registrationId}/landowner-info/${this.landownerId}`;
      this.$axios.get(url).then((response) => {
        // debugger;
        this.newapplicant.salutation = response.data.data.salutation;
        this.newapplicant.citizenship_no = response.data.data.citizenship_no;
        this.newapplicant.phone_no = response.data.data.phone_no;
        this.newapplicant.applicant_name_np = response.data.data.landowner_name_np;
        this.newapplicant.applicant_name = response.data.data.landowner_name;
        this.newapplicant.ward_no = "";
        this.newapplicant.citizenship_issue_district_id =
          response.data.data.citizenship_district_id;
      });
    },
    clearAllData() {
      this.newapplicant.id = 0;
      this.newapplicant.reg_id = this.registrationId;
      this.newapplicant.applicant_type_id = "";
      this.newapplicant.salutation = "";
      this.newapplicant.ho_applicant_relation = "";
      this.newapplicant.nominee_name = "";
      this.newapplicant.nominee_name_np = "";
      this.newapplicant.applicant_name = "";
      this.newapplicant.applicant_name_np = "";
      this.newapplicant.citizenship_no = "";
      this.newapplicant.citizenship_issue_district_id = "";
      this.newapplicant.phone_no = "";
      this.newapplicant.photo = "";
      this.newapplicant.ward_no = "";
    },
    onFileChange(e) {
      const self = this;

      const files = e.target.files || e.dataTransfer.files;
      console.log(files[0].size);
      if (files.length > 0 && files[0].size < 1024 * 1024 * 5) {
        for (let i = 0; i < files.length; i += 1) {
          self.formData.append("photo", files[i], files[i].name);
        }
      } else {
        document.getElementById("file-upload").value = "";
        alert("file greater than 5mb");
      }
    },
    buildFormData(formData, data, parentKey) {
      if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
      } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
      }
    },
    jsonToFormData(data) {
      // const formData = new FormData();

      this.buildFormData(this.formData, data);

      return this.formData;
    },
    save(isNext) {
      this.isLoading = true;
      const self = this;
      // for(let i=0;i<this.newapplicant.length;i++){
      //   self.formData.append("")
      // }
      this.formData = this.jsonToFormData(this.newapplicant);
      // self.formData.append("applicant_type_id", this.newapplicant.applicant_type_id);
      // self.formData.append("salutation", this.newapplicant.salutation);
      // self.formData.append("ho_applicant_relation", this.newapplicant.ho_applicant_relation);
      // self.formData.append("nominee_name", this.newapplicant.nominee_name);
      // self.formData.append("nominee_name_np", this.newapplicant.nominee_name_np);
      // self.formData.append("applicant_name", this.newapplicant.applicant_name);
      // self.formData.append("applicant_name_np", this.newapplicant.applicant_name_np);
      // self.formData.append("citizenship_no", this.newapplicant.citizenship_no);
      // self.formData.append(
      //   "citizenship_issue_district_id",
      //   this.newapplicant.citizenship_issue_district_id
      // );
      // self.formData.append("phone_no", this.newapplicant.phone_no);
      // self.formData.append("reg_id", this.newapplicant.reg_id);
      // self.formData.append("ward_no", this.newapplicant.ward_no);
      if (
        this.newapplicant.id === "null" ||
        this.newapplicant.id === 0 ||
        this.newapplicant.id === undefined
      ) {
        self.formData.append("_method", "POST");
        const saveUrl = `/api/registration/${this.registrationId}/applicant`;
        this.$axios
          .post(saveUrl, self.formData)
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.applicant.push(response.data.data);
              this.isLoading = false;
              this.snackbar.show = true;
              this.successfulMessage = "Successfully Added";
              // this.alertSuccess("Successfully Added !!");

              if (isNext) {
                this.nextStep(1);
              }
              this.$refs.form.reset();
              this.newapplicant.id = 0; //reset the edit id
            }
          })
          .catch((error) => {
            // alert(2);
            this.isLoading = false;
            let message = [];
            let errorResponse = error.response.data.errors;
            for (var i in errorResponse) {
              // errorText = errorText + `${i}: ${errorResponse[i]} \n`;
              message.push(errorResponse[i][0] + "<br>");
            }
            this.alertError(message);
          });
      } else {
        self.formData.append("_method", "PUT");
        const eurl = `/api/registration/${this.$route.params.regId}/applicant/${this.newapplicant.id}`;
        this.$axios
          .post(eurl, self.formData)
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              const i = this.applicant.map((item) => item.id).indexOf(this.newapplicant.id); // find index of your object
              this.applicant[i].nominee_name_np = this.newapplicant.nominee_name_np;
              // this.applicant[i].ward_no = this.newapplicant.ward_no;
              this.applicant[i].citizenship_no = this.newapplicant.citizenship_no;
              this.applicant[i].phone_no = this.newapplicant.phone_no;
              this.isLoading = false;
              this.snackbar.show = true;
              this.successfulMessage = "Successfully Updated";
              // this.alertUpdate("Successfully Updated !!");

              if (isNext) {
                this.nextStep(1);
              }
              this.$refs.form.reset();
              this.newapplicant.id = 0; //reset the edit id
            }
          })
          .catch((error) => {
            this.isLoading = false;
            let message = [];
            let errorResponse = error.response.data.errors;
            for (var i in errorResponse) {
              // errorText = errorText + `${i}: ${errorResponse[i]} \n`;
              message.push(errorResponse[i][0] + "<br>");
            }
            this.alertError(message);
          });
      }
    },
    edit(id) {
      //   debugger;
      //    const url = `api/registration/${this.$route.params.regId}/applicant/${this.$route.params.id}`;
      const url = `api/registration/${this.$route.params.regId}/applicant/${id}`;
      this.$axios.get(url).then((response) => {
        // debugger;
        this.newapplicant = response.data.data;
        this.newapplicant.ward_no = ""; // 'null' data seen as string by js resulting in invalid data during update
        this.uploadedDocument = this.basePath + response.data.data.photo;
        console.log(this.uploadedDocument);
      });
    },
    deleterecord(id) {
      const deleteurl = `api/registration/${this.$route.params.regId}/applicant/${id}`;
      this.$axios
        .delete(deleteurl)
        .then((response) => {
          // debugger;
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Successfully Deleted";
            // this.alertDelete("Successfully Deleted !!");

            const i = this.applicant.map((item) => item.id).indexOf(id); // find index of your object
            this.applicant.splice(i, 1);
          }
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 60px;
}

.custom-text-field,
.v-text-field,
.v-text-field--enclosed,
.v-input__slot {
  padding: 0;
}

.v-text-field__details {
  margin: 0;
  padding: 0;
}

label span {
  color: red;
}

.table-title {
  font-size: 17px;
}
</style>
