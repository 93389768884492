<template>
  <div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <v-card class="sober d-flex flex-column">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title> चारकिल्ला </v-toolbar-title>
      </v-toolbar>
      <!-- <v-form ref="form" class="mx-4" v-model="valid">
        <v-row>
          <v-col md="2">
            <label> दिशा <span>*</span></label>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="newCharkilla.direction"
              :rules="[required('Direction')]"
              class="custom-text-field v-text-field__details"
              :items="directions"
              item-value="value"
              item-text="value"
              :key="id"
              disabled
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <label> तर्फ़ <span>*</span></label>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="newCharkilla.direction_through"
              :rules="[required('Direction position')]"
              class="custom-text-field v-text-field__details"
              :items="directionsThrough"
              item-value="id"
              item-text="value"
              :key="id"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <label> लेन्डस्केप टाईप <span>*</span></label>
          </v-col>

          <v-col cols="4">
            <v-select
              v-model="newCharkilla.landscape_type_id"
              :rules="[required('Landscape Type')]"
              :items="landscapeType"
              item-value="id"
              item-text="landscape_type_name"
              :key="id"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="2">
            Road width (m)
          </v-col>
          <v-col md="4">
            <v-text-field v-model="newCharkilla.road_width" label=""></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="2">
            Name
          </v-col>
          <v-col md="4">
            <v-text-field v-model="newCharkilla.name" label=""></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="2">
            कित्ता
          </v-col>
          <v-col md="4">
            <v-text-field v-model="newCharkilla.plot_no" label=""></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="2">
            Actual Setback (m)
          </v-col>
          <v-col md="4">
            <v-text-field v-model="newCharkilla.actual_setback" label=""></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="2">
            Standard Setback (m)
          </v-col>
          <v-col md="4">
            <v-text-field v-model="newCharkilla.standard_setback" label=""></v-text-field>
          </v-col>
        </v-row>
        <v-btn primary v-if="isPostAuthorized" v-on:click="save" class="primary" :disabled="!valid"
          >Save</v-btn
        >
        <v-btn class="ma-1" color="primary" @click="nextStep(1)">
          Next
        </v-btn>
        <v-snackbar
          v-model="snackbar.show"
          :bottom="y === 'bottom'"
          :color="snackbar.color"
          :left="x === 'left'"
          :multi-line="mode === 'multi-line'"
          :right="x === 'right'"
          :timeout="3000"
          :top="y === 'top'"
          :vertical="mode === 'vertical'"
        >
          {{ successfulMessage }}
        </v-snackbar>
        <br />
      </v-form> -->
      <!-- <v-form ref="form" class="mx-4" v-model="valid">
        <v-row>
          <thead>
            <tr>
              <th>दिशा</th>
              <th>तर्फ़</th>
              <th>लेन्डस्केप टाईप</th>
              <th>Road width</th>
              <th>Name</th>
              <th>Actual Setback (m)</th>
              <th>Standard Setback (m)</th>
            </tr>
          </thead>
        </v-row>
      </v-form> -->

      <!-- New Table -->
      <v-form ref="form" class="mx-4 my-4" v-model="valid">
        <v-simple-table class="sober">
          <template v-slot:default>
            <thead>
              <tr class="registrationtable">
                <th class="text-left registrationtable text-bold" width="10%">
                  दिशा <span class="star-red">*</span>
                </th>
                <th class="text-left registrationtable text-bold">
                  लेन्डस्केप टाईप <span class="star-red">*</span>
                </th>
                <th v-if="false" class="text-left registrationtable text-bold">
                  तर्फ़ <span class="star-red">*</span>
                </th>
                <th class="text-left registrationtable text-bold" width="8%">Road width (m)</th>
                <th class="text-left registrationtable text-bold" width="20%">नाम</th>
                <th class="text-left registrationtable text-bold">कित्ता</th>
                <th class="text-left registrationtable text-bold">Actual Setback (m)</th>
                <th class="text-left registrationtable text-bold">Standard Setback (m)</th>
              </tr>
            </thead>
            <tbody v-for="c in newCharkilla.charkilla" :key="c.id">
              <tr>
                <td class="registrationtable">
                  <v-select v-model="c.direction" :rules="[required('Direction')]" class="custom-text-field v-text-field__details" :items="directions" item-value="value"
                    item-text="value" :key="id" disabled></v-select>
                </td>
                <td class="registrationtable">
                  <v-select v-model="c.landscape_type_id" :rules="[required('Landscape Type')]" :items="landscapeType" item-value="id" item-text="landscape_type_name"
                    :key="id"></v-select>
                </td>
                <td v-if="false">
                  <v-select v-model="c.direction_through" :rules="[required('Direction position')]" class="custom-text-field v-text-field__details" :items="directionsThrough"
                    item-value="id" item-text="value" :key="id"></v-select>
                </td>
                <td>
                  <v-text-field v-model="c.road_width" :rules="regFormat('Road Width', '^[0-9०-९]*[.]?[0-9०-९]+?$')" label=""></v-text-field>
                </td>
                <td>
                  <v-textarea v-model="c.name" :rules="regFormat('नाम', '^[\u0900-\u0966 \u0970-\u097F \.]+$')" rows="1" auto-grow label=""></v-textarea>
                </td>
                <td>
                  <v-text-field v-model="c.plot_no" :rules="regFormat('कित्ता', '^[0-9०-९/ ,]*$')" label=""></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="c.actual_setback" :rules="regFormat('Actual Setback', '^[0-9०-९]*[.]?[0-9०-९]+?$')" label=""></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="c.standard_setback" :rules="regFormat('Standard Setback', '^[0-9०-९]*[.]?[0-9०-९]+?$')" label=""></v-text-field>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-row>
          <v-col class="my-4">
            <v-btn primary v-if="isPostAuthorized" v-on:click="save(false)" class="primary" :disabled="!valid">Save</v-btn>
            <v-btn primary v-on:click="save(true)" v-if="isPostAuthorized" class="primary ml-1" :disabled="!valid">Save &amp; Next</v-btn>
            <v-btn class="ma-1" color="primary" @click="nextStep(1)"> Skip </v-btn>
          </v-col>
        </v-row>
        <br><br>
        <v-snackbar v-model="snackbar.show" :bottom="y === 'bottom'" :color="snackbar.color" :left="x === 'left'" :multi-line="mode === 'multi-line'" :right="x === 'right'"
          :timeout="3000" :top="y === 'top'" :vertical="mode === 'vertical'">
          {{ successfulMessage }}
        </v-snackbar>
      </v-form>
    </v-card>
    <br />
  </div>
</template>
<script>
export default {
  name: "Charkilla",
  props: {
    nextStep: {
      type: Function,
    },
    registrationId: {
      type: [String, Number],
    },
    id: {
      type: [String, Number],
    },
    application: {
      type: Object,
    },
    // isPostAuthorized: {
    //   type: Boolean,
    // },
  },

  data() {
    return {
      valid: false,
      required(propertyType) {
        return (v) => !!v || `${propertyType} is required`;
      },
      requiredFormat(propertyType, regexexp) {
        var regex = new RegExp(regexexp, "g");
        return [
          (v) => !!v || `${propertyType} is required`,
          (v) => regex.test(v) || `${propertyType}को फर्मेट मिलेन ।`,
          // /^२[०-९]{3}-[०१]?[०-९]-[०-३]?[०-९]$/   ‍=> Date
        ];
      },
      regFormat(propertyType, regexexp) {
        var regex = new RegExp(regexexp, "g");
        return [
          (v) => !v || regex.test(v) || `${propertyType}को फर्मेट मिलेन ।`,
          // /^२[०-९]{3}-[०१]?[०-९]-[०-३]?[०-९]$/   ‍=> Date
        ];
      },
      landscapeType: [],
      userRoleId: localStorage.getItem("auth_user_role_id"),
      date: "",
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      directions: [
        { id: "e", value: "पुर्व" },
        { id: "w", value: "पश्चिम" },
        { id: "n", value: "उत्तर" },
        { id: "s", value: "दक्षिण" },
      ],
      directionsThrough: [
        { id: "front", value: "Front" },
        { id: "left", value: "Left" },
        { id: "right", value: "Right" },
        { id: "back", value: "Back" },
      ],
      charkilla: [],
      newCharkilla: {
        charkilla: [],
      },
      isValid: true,
      isLoading: true,
      isPostAuthorized: false,
    };
  },
  created() {
    // this.getRole();
    this.getNibedanAuthorization();
  },
  mounted() {
    this.addkilla();

    const landscapeTypeUrl = "api/landscape-type";
    this.$axios
      .get(landscapeTypeUrl)
      .then((response) => {
        this.landscapeType = response.data;
      })
      .catch((error) => {
        console.log(`api error:${error}`);
      });

    const url = `api/registration/${this.registrationId}/charkilla`;
    this.$axios
      .get(url)
      .then((response) => {
        // debugger;
        if (response.data.data.length !== 0) {
          this.newCharkilla.charkilla = [];
          this.newCharkilla.charkilla = response.data.data;
          this.newCharkilla.charkilla = this.sortCharkillaByDirection(this.newCharkilla.charkilla);
          var charlen = this.newCharkilla.charkilla.length;
          let directioncopy = [
            { id: "e", value: "पुर्व" },
            { id: "w", value: "पश्चिम" },
            { id: "n", value: "उत्तर" },
            { id: "s", value: "दक्षिण" },
          ];

          var charlen = this.newCharkilla.charkilla.length;

          if (this.newCharkilla.charkilla.length < 4) {
            for (let j = 0; j < charlen; j++) {
              var dir = this.newCharkilla.charkilla[j].direction;
              var dirIndex = directioncopy
                .map(function (e) {
                  return e.value;
                })
                .indexOf(dir);
              directioncopy.splice(dirIndex, 1);
            }

            for (let i = 1; i < 5 - charlen; i++) {
              this.newCharkilla.charkilla.push({
                id: 0,
                reg_id: this.registrationId,
                direction: directioncopy[i - 1].value,
                direction_through: "",
                landscape_type_id: "",
                landscape_type: "",
                landscape_type_en: "",
                road_width: "",
                name: "",
                plot_no: "",
                actual_setback: "",
                standard_setback: "",
                land_measurement: "",
                building_measurement: "",
                land_building_distance: "",
                created_by: "",
              });
            }
          }
        }

        this.isLoading = false; //stop the loading spinner
        // this.newCharkilla.clear();
      })
      .catch((error) => {
        console.log(`api error:${error}`);
        this.isLoading = false; //stop the loading spinner
      });

    console.log(this.application);
  },
  methods: {
    // alertSuccess(message) {
    //   this.$swal("Added!", message, "success");
    // },
    // alertUpdate(message) {
    //   this.$swal("Updated!", message, "success");
    // },
    // alertDelete(message) {
    //   this.$swal("Deleted!", message, "success");
    // },
    sortCharkillaByDirection(unsorted_data) {
      const directions = [
        { order: 1, value: "पुर्व" },
        { order: 2, value: "पश्चिम" },
        { order: 3, value: "उत्तर" },
        { order: 4, value: "दक्षिण" },
      ];
      let merged = [];
      for (let i = 0; i < unsorted_data.length; i++) {
        merged.push({
          ...unsorted_data[i],
          ...(directions.find((item) => item.value == unsorted_data[i].direction))
        });
        delete merged[i].value;
      }
      merged.sort((a, b) => { return a.order - b.order });
      return merged;
    },
    alertError(message) {
      this.$swal.fire({
        icon: "error",
        title: message,
        text: "Please have a look on the form!",
      });
    },
    getNibedanAuthorization() {
      this.isPostAuthorized = this.$store.getters.nibedanBibaranAuthData;
    },
    save(isNext) {
      // debugger;
      this.isLoading = true;
      if (
        this.newCharkilla.charkilla[0].id === "null" ||
        this.newCharkilla.charkilla[0].id === 0 ||
        this.newCharkilla.charkilla[0].id === undefined
      ) {
        const saveUrl = `/api/registration/${this.registrationId}/charkilla`;
        this.$axios
          .post(saveUrl, this.newCharkilla)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.snackbar.show = true;
              this.isLoading = false;
              this.successfulMessage = "Successfully Added";
              // this.alertSuccess("Successfully Added");
              this.newCharkilla.charkilla = response.data.data;
              this.newCharkilla.charkilla = this.sortCharkillaByDirection(this.newCharkilla.charkilla);
              if (isNext) {
                this.nextStep(1);
              }
            }
          })
          .catch((error) => {
            this.isLoading = false;
            // alert(2);
            // console.log(`api error:${error}`);
            let message = [];
            let errorResponse = error.response.data.errors;
            for (var i in errorResponse) {
              // errorText = errorText + `${i}: ${errorResponse[i]} \n`;
              message.push(errorResponse[i][0] + "<br>");
            }
            this.alertError(message);
          });
      } else {
        const eurl = `/api/registration/${this.$route.params.regId}/charkilla/${this.newCharkilla.charkilla[0].id}`;
        this.$axios
          .put(eurl, this.newCharkilla)
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.snackbar.show = true;
              this.successfulMessage = "Successfully Updated";
              // this.alertUpdate("Successfully Updated !!");
              this.newCharkilla.charkilla = response.data.data;
              this.newCharkilla.charkilla = this.sortCharkillaByDirection(this.newCharkilla.charkilla);
              this.isLoading = false;
              if (isNext) {
                this.nextStep(1);
              }
            }
          })
          .catch((error) => {
            this.isLoading = false;
            // console.log(error.response.data.message);
            // this.alertError(error);
            let message = [];
            let errorResponse = error.response.data.errors;
            for (var i in errorResponse) {
              // errorText = errorText + `${i}: ${errorResponse[i]} \n`;
              message.push(errorResponse[i][0] + "<br>");
            }
            this.alertError(message);
          });
      }
    },
    edit(id) {
      //   debugger;
      //    const url = `api/registration/${this.$route.params.regId}/charkilla/${this.$route.params.id}`;
      const url = `api/registration/${this.$route.params.regId}/charkilla/${id}`;
      this.$axios.get(url).then((response) => {
        // debugger;
        this.newCharkilla = response.data.data;
        // console.log(response.data.data);
      });
    },
    deleterecord(id) {
      const deleteurl = `api/registration/${this.$route.params.regId}/charkilla/${id}`;
      this.$axios
        .delete(deleteurl)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Successfully Deleted";
            // this.alertDelete("Successfully Deleted");
            const i = this.charkilla.map((item) => item.id).indexOf(id); // find index of your object
            this.charkilla.splice(i, 1);
          }
          console.log(response);
        })
        .catch((error) => {
          // console.log(error);
          let message = [];
          let errorResponse = error.response.data.errors;
          for (var i in errorResponse) {
            // errorText = errorText + `${i}: ${errorResponse[i]} \n`;
            message.push(errorResponse[i][0] + "<br>");
          }
          this.alertError(message);
        });
    },
    addkilla() {
      for (let i = 0; i < 4; i++) {
        this.newCharkilla.charkilla.push({
          id: 0,
          reg_id: this.registrationId,
          direction: this.directions[i].value,
          direction_through: "",
          landscape_type_id: "",
          road_width: "",
          name: "",
          plot_no: "",
          actual_setback: "",
          standard_setback: "",
          land_measurement: "",
          building_measurement: "",
          land_building_distance: "",
        });
      }
    },
  },
};
</script>

<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 58px;
}

.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}

label span {
  color: red;
}

.text-bold {
  font-size: 18px;
  font-weight: 700;
}

.star-red {
  color: red;
}
</style>
