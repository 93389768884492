<template>
  <div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <v-card class="mb-12" color="sober" height="auto">
      <v-toolbar color="primary" dark flat>
        <!-- <v-toolbar-title> निर्माणकर्मी / ठेकेदार </v-toolbar-title> -->
        <v-toolbar-title> निर्माणकर्मी / निर्माण व्यवसायी </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="mx-4 my-4" v-model="valid">
        <v-row>
          <v-col cols="6" md="3"> नाम <span class="required-star">*</span> </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model="newcontractor.name"
              label="Contractor Name"
              class="mtn-1"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3"> License no </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model="newcontractor.license_no"
              label="License Number"
              class="mtn-1"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3"> Phone <span class="required-star">*</span> </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model="newcontractor.phone_no"
              label="Phone Number"
              class="mtn-1"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-snackbar
          v-model="snackbar.show"
          :bottom="y === 'bottom'"
          :color="snackbar.color"
          :left="x === 'left'"
          :multi-line="mode === 'multi-line'"
          :right="x === 'right'"
          :timeout="3000"
          :top="y === 'top'"
          :vertical="mode === 'vertical'"
        >
          {{ successfulMessage }}
        </v-snackbar>
        <v-row>
          <v-col class="my-4">
            <v-btn
              primary
              v-if="isPostAuthorized"
              v-on:click="save(false)"
              class="primary"
              :disabled="!valid"
              >Save</v-btn
            >
            <v-btn
              primary
              v-on:click="save(true)"
              v-if="isPostAuthorized"
              class="primary ml-1"
              :disabled="!valid"
              >Save &amp; Next</v-btn
            >
            <v-btn class="ma-1" color="primary" @click="nextStep(1)"> Skip </v-btn>
          </v-col>
        </v-row>
        <br /><br /><br />
      </v-form>
      <v-toolbar color="primary my-4" dark flat>
        <v-toolbar-title> List </v-toolbar-title>
      </v-toolbar>
      <v-simple-table class="sober">
        <template v-slot:default>
          <thead>
            <tr class="registrationtable">
              <th class="text-left registrationtable table-title">Name</th>
              <th class="text-left registrationtable table-title">Phone</th>
              <th class="text-left registrationtable table-title">Certificate No</th>
              <th class="text-left registrationtable table-title">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="registrationtable"
              v-for="contractorList in contractor"
              :key="contractorList.id"
            >
              <td class="registrationtable">
                {{ contractorList.name }}
              </td>
              <td>{{ contractorList.phone_no }}</td>
              <td class="registrationtable">{{ contractorList.license_no }}</td>
              <td>
                <v-btn
                  small
                  color="primary"
                  v-if="isPostAuthorized"
                  v-on:click="edit(contractorList.id)"
                  >Edit</v-btn
                >
                <!-- <v-btn
                  small
                  color="primary"
                  v-if="isPostAuthorized"
                  v-on:click="deleterecord(contractorList.id)"
                  >Delete</v-btn
                > -->
                <custom-dialog
                  v-if="isPostAuthorized"
                  :click-dialog="deleterecord"
                  :regId="contractorList.id"
                  click-type="delete"
                >
                  Delete
                </custom-dialog>
                <v-btn small color="primary" v-else v-on:click="edit(landownerList.id)">View</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <form-history :historyData="historyData"></form-history>
  </div>
</template>
<script>
import FormHistory from "../../history/FormHistory.vue";

export default {
  name: "contractor",
  props: {
    nextStep: {
      type: Function,
    },
    registrationId: {
      type: [String, Number],
    },
    id: {
      type: [String, Number],
    },
    application: {
      type: Object,
    },
    // isPostAuthorized: {
    //   type: Boolean,
    // },
    regConstructionId: {
      type: Number,
    },
  },
  components: {
    FormHistory,
  },

  data() {
    return {
      valid: false,
      same_as_old_data: false,
      historyData: {
        apiUrl: `api/registration/${this.registrationId}/contractor/history`,
        formData: [
          {
            title: "नाम ",
            key: "name",
          },
          {
            title: "License no",
            key: "license_no",
          },
          {
            title: "फोन नं",
            key: "phone_no",
          },
        ],
      },
      isLoading: true,
      date: "",
      userRoleId: localStorage.getItem("auth_user_role_id"),
      contractor: [],
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      newcontractor: {
        id: 0,
        reg_id: this.registrationId,
        name: "",
        license_no: "",
        phone_no: "",
      },
      isPostAuthorized: false,
    };
  },
  created() {
    this.getNibedanAuthorization();
  },
  mounted() {
    // console.log(localStorage.getItem("token"));

    const url = `api/registration/${this.registrationId}/contractor`;
    this.$axios
      .get(url)
      .then((response) => {
        // debugger;
        this.contractor = response.data.data;
        this.edit(this.contractor[0].id);
        this.isLoading = false; //stop the loading spinner
        // this.newcontractor.clear();
      })
      .catch((error) => {
        console.log(`api error:${error}`);
        this.isLoading = false; //stop the loading spinner
      });

    this.getContractorFormAuthorization();
  },
  methods: {
    // alertSuccess(message) {
    //   this.$swal("Added!", message, "success");
    // },
    // alertUpdate(message) {
    //   this.$swal("Updated!", message, "success");
    // },
    // alertDelete(message) {
    //   this.$swal("Deleted!", message, "success");
    // },
    alertError(message) {
      this.$swal.fire({
        icon: "error",
        title: message,
        text: "Please have a look on the form!",
      });
    },
    getNibedanAuthorization() {
      this.isPostAuthorized = this.$store.getters.nibedanBibaranAuthData;
    },
    clearAllData() {
      this.newcontractor.id = 0;
      this.newcontractor.reg_id = this.registrationId;
      this.newcontractor.name = "";
      this.newcontractor.license_no = "";
      this.newcontractor.phone_no = "";
    },
    save(isNext) {
      //   debugger;
      this.isLoading = true;
      if (
        this.newcontractor.id === "null" ||
        this.newcontractor.id === 0 ||
        this.newcontractor.id === undefined
      ) {
        const saveUrl = `/api/registration/${this.registrationId}/contractor`;
        this.$axios
          .post(saveUrl, this.newcontractor)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.contractor.push(response.data.data);
              this.isLoading = false;
              this.snackbar.show = true;
              this.successfulMessage = "Successfully Added";
              // this.alertSuccess("Successfully Added !!");
              if (isNext) {
                this.nextStep(1);
              }
              this.$refs.form.reset();
            }
          })
          .catch((error) => {
            this.isLoading = false;
            // console.log(`api error:${error}`);
            // this.alertError(error.response.data.message);
            // let errorText = "";
            let message = [];
            let errorResponse = error.response.data.errors;
            for (var i in errorResponse) {
              // errorText = errorText + `${i}: ${errorResponse[i]} \n`;
              message.push(errorResponse[i][0]+"<br>");
            }
            this.alertError(message);
          });
      } else {
        const eurl = `/api/registration/${this.$route.params.regId}/contractor/${this.newcontractor.id}`;
        this.$axios
          .put(eurl, this.newcontractor)
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              const i = this.contractor.map((item) => item.id).indexOf(this.newcontractor.id); // find index of your object
              this.contractor[i].name = this.newcontractor.name;
              this.contractor[i].license_no = this.newcontractor.license_no;
              this.contractor[i].phone_no = this.newcontractor.phone_no;
              this.isLoading = false;
              this.snackbar.show = true;
              this.successfulMessage = "Successfully Updated";
              // this.alertUpdate("Successfully Updated !!");
              if (isNext) {
                this.nextStep(1);
              }
              this.$refs.form.reset();
              this.newcontractor.id = 0;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            let message = [];
            let errorResponse = error.response.data.errors;
            for (var i in errorResponse) {
              // errorText = errorText + `${i}: ${errorResponse[i]} \n`;
              message.push(errorResponse[i][0]+"<br>");
            }
            this.alertError(message);
          });
      }
    },
    edit(id) {
      //   debugger;
      //    const url = `api/registration/${this.$route.params.regId}/contractor/${this.$route.params.id}`;
      const url = `api/registration/${this.$route.params.regId}/contractor/${id}`;
      this.$axios.get(url).then((response) => {
        // debugger;
        this.newcontractor = response.data.data;
        console.log(response.data.data);
      });
    },
    deleterecord(id) {
      const deleteurl = `api/registration/${this.$route.params.regId}/contractor/${id}`;
      this.$axios
        .delete(deleteurl)
        .then((response) => {
          // debugger;
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Successfully Deleted";
            // this.alertDelete("Successfully Deleted !!");
            const i = this.contractor.map((item) => item.id).indexOf(id); // find index of your object
            this.contractor.splice(i, 1);
          }
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getContractorFormAuthorization() {
      const authUrl = `api/registration/${this.$route.params.regId}/contractor/is-authorized`;
      this.$axios
        .get(authUrl)
        .then((response) => {
          this.isPostAuthorized = response.data.data;
          // console.log(`contractor add/edit: ${this.contractorFormAddEdit}`);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 58px;
}

.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}
.table-title {
  font-size: 17px;
}
.mtn-1 {
  margin-top: -1rem;
}
.required-star {
  color: red;
}
</style>
